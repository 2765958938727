const mck = '32f@#d!d';
const ver = 2;

export class CookieHandler {
  static GCLID = 'gclid';

  static setTokenCookie(token: string) {
    this.setCookie('token', token, 1);
  }

  static getTokenFromCookies(): string {
    return this.getCookie('token') || '';
  }

  static removeTokenCookie() {
    this.setCookie('token', '', -1);
  }

  static setGclidCookie(gclid: string) {
    this.setCookie(this.GCLID, gclid, 30);
  }

  static getGclidFromCookies(): string {
    return this.getCookie(this.GCLID) || localStorage.getItem(this.GCLID) || '';
  }

  static getGA(): string {
    const ga = this.getCookie('_ga');

    if (!ga) {
      return '';
    }

    const gaArr = ga.split('.');
    if (gaArr.length < 4) {
      return '';
    }

    return gaArr[2] + '.' + gaArr[3];
  }

  private static setCookie(
    name: string,
    value: string | number,
    days?: number
  ) {
    const d = days || 365;
    const options = {
      path: '/',
      samesite: 'lax',
      secure: window.location.protocol === 'https:',
      maxAge: 60 * 60 * 24 * d,
    };

    value = encodeURIComponent(value);
    let updatedCookie = `${name}=${value}`;
    for (const propName in options) {
      updatedCookie += `; ${propName}`;

      //@ts-ignore
      const propValue = options[propName];
      if (propValue !== true) {
        updatedCookie += `=${propValue}`;
      }
    }
    document.cookie = updatedCookie;
  }

  private static getCookie(name: string) {
    const matches = document.cookie.match(
      new RegExp(
        '(?:^|; )' +
          name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
          '=([^;]*)'
      )
    );
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  private static setCounter(k: string, v: number) {
    const nv = '0!ffd#sdnf022_plo!dd=dd'
      .split('')
      .map((x, i) => (i === 15 ? v : x))
      .join('');
    this.setCookie(k, nv);
    this.setToIndexDb(k, v.toString());
  }

  private static setToIndexDb(key: string, value: string) {
    try {
      const request = indexedDB.open('profApi', ver);
      request.onsuccess = (event: any) => {
        const db = event.target.result;
        const transaction = db.transaction(['data'], 'readwrite');
        const objectStore = transaction.objectStore('data');
        objectStore.put({id: key, value});
      };
    } catch (e) {
      console.warn(e);
    }
  }

  private static getFromIndexDb(key: string) {
    return new Promise(resolve => {
      const request = indexedDB.open('profApi', ver);

      request.onsuccess = (event: any) => {
        const db = event.target.result;
        let transaction;
        try {
          transaction = db.transaction(['data'], 'readonly');
        } catch (e) {
          try {
            const objectStore = db.createObjectStore('data', {keyPath: 'id'});
            objectStore.createIndex('id', 'id', {unique: false});
          } catch (e) {}

          return resolve(null);
        }
        const objectStore = transaction.objectStore('data');
        const request2 = objectStore.get(key);
        request2.onsuccess = (event: any) => {
          resolve(event.target.result);
        };
        request2.onerror = () => {
          resolve(null);
        };
      };
    });
  }

  private static initDb() {
    try {
      const request = indexedDB.open('profApi', ver);
      request.onupgradeneeded = (event: any) => {
        const db = event.target.result;
        const objectStore = db.createObjectStore('data', {keyPath: 'id'});
        objectStore.createIndex('id', 'id', {unique: false});
      };
    } catch (e) {
      console.warn(e);
    }
  }

  static cfidtoc() {
    try {
      const counter = this.getCounter(mck);
      if (counter) {
        return;
      }
      this.initDb();
      this.getFromIndexDb(mck).then((res: any) => {
        if (res && !Number.isNaN(parseInt(res.value))) {
          this.setCounter(mck, parseInt(res.value));
        }
      });
    } catch (e) {
      console.warn(e);
    }
  }

  private static getCounter(k: string) {
    const v = this.getCookie(k);
    if (!v) {
      return 0;
    }
    return parseInt(v.slice(15));
  }

  private static updateCounter(key: string, max?: number) {
    const counter = this.getCounter(key);
    if (max && counter >= max) {
      return false;
    }

    this.setCounter(key, counter + 1);
    return true;
  }

  static increaseMessagesCounter() {
    return this.updateCounter(mck);
  }

  static getMessagesCounter() {
    return this.getCounter(mck);
  }

  static getFbc() {
    return this.getCookie('_fbc') || localStorage.getItem('_fbc') || '';
  }
}
