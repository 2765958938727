export type BotStatus = 'active' | 'muted' | 'inactive' | 'deleted';

export const enum BotType {
  REGULAR = 'regular',
  TALKING_HEADS = 'talking_heads',
}

export type AnimationPipeline = 'high_speed' | 'high_quality' | 'optimal';

export type ShortBotData = {
  name: string;
  id: string;
  messagesQty: number;
  status: BotStatus;
  photoUrl?: string;
  userPaid: boolean;
  type: BotType;
  apiKey?: string;
  videoUrl?: string;
};

export const BOT_FEATURES = [
  'Security',
  'Hate Detect',
  'Remember Me',
  'Empty 1',
  'Empty 2',
  'Empty 3',
] as const;

export type BOT_FEATURE = typeof BOT_FEATURES[number];

export type BotData = {
  name: string;
  id: string;
  status: BotStatus;
  photoUrl?: string;
  videoUrl?: string;
  greeting?: string;
  voice: string;
  features: BOT_FEATURE[];
  persona_facts: string[];
  type: BotType;
  animation_pipeline?: AnimationPipeline;
};
