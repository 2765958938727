import {useNavigate} from 'react-router-dom';
import {SuccessPaymentPage} from '../../common-lib/src/components/SuccessPaymentPage/SuccessPaymentPage';
import {URLS} from '../../common/constants';
import {useEffect} from 'react';
import {profAmplitudeApi} from '../../ampli/amplitudeApi';

export const SuccessPayment = () => {
  const navigate = useNavigate();
  useEffect(() => {
    profAmplitudeApi.subscriptionPaid();
  }, []);

  return (
    <div style={{marginTop: 120}}>
      <SuccessPaymentPage
        onClick={() => navigate(URLS.subscription)}
        text="Back to subscription page"
      />
    </div>
  );
};
