import './BigCard.css';
import bulletImg from './images/bullet.svg';

type Props = {
  src: string;
  text: string;
  li: string[];
  color: string;
};

export const BigCard = ({src, text, li, color}: Props) => {
  return (
    <div className={`big-card big-card_${color}`}>
      <img src={src} alt="big card" className="main-img" />
      <h2 className="big-card__text">{text}</h2>
      <ul className="big-card__list">
        {li.map((item, index) => (
          <li key={index}>
            <img src={bulletImg} alt="bullet" />
            <p className="list-text">{item}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};
