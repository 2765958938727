import {Chip} from '@mui/material';
import './Tag.css';

type Props = {
  tag: string;
  onClick: (tag: string) => void;
  isSelected?: boolean;
  icon?: string | null;
};

export const Tag = ({tag, onClick, isSelected, icon}: Props) => {
  const label = icon ? (
    <div className="row">
      <img src={icon} alt="tag icon" className="tag__icon" />
      <p>{tag}</p>
    </div>
  ) : (
    tag
  );
  return (
    <Chip
      label={label}
      className={`tag ${isSelected ? 'tag_selected' : ''}`}
      onClick={() => onClick(tag)}
    />
  );
};
