import './ChatMain.css';
import {Message} from '../../../api/Api';
import {ChatPerson} from '../../../data/chatData';

type Props = {
  isLoading: boolean;
  messages: Message[];
  person?: ChatPerson;
  withPhoto?: boolean;
};

const MoreSimpleMessage = ({message}: {message: Message}) => {
  return (
    <div
      className={`chat__message ${
        message.turn === 'user' ? 'chat__message_user' : 'chat__message_bot'
      }`}
    >
      <div className="message__text">{message.message}</div>
    </div>
  );
};

const SimpleMessage = ({message}: {message: Message}) => {
  const {image} = message;

  return (
    <>
      <MoreSimpleMessage message={message} />
      {image && (
        <div
          className={`chat__message ${
            message.turn === 'user' ? 'chat__message_user' : 'chat__message_bot'
          }`}
        >
          <img
            src={image}
            alt="chat-avatar"
            className="chat__message_bot-image"
          />
        </div>
      )}
    </>
  );
};

const MessageWithPhoto = ({
  message,
  person,
}: {
  message: Message;
  person: ChatPerson;
}) => {
  const {image} = message;

  return (
    <>
      <div className="chat__message-row">
        <img
          className="message-row__photo"
          src={person.src}
          alt={person.name}
        />
        <MoreSimpleMessage message={message} />
      </div>
      {image && (
        <div
          className={`chat__message ${
            message.turn === 'user' ? 'chat__message_user' : 'chat__message_bot'
          }`}
          style={{marginLeft: '40px'}}
        >
          <img
            src={image}
            alt="chat-avatar"
            className="chat__message_bot-image"
          />
        </div>
      )}
    </>
  );
};

export const ChatMain = ({
  isLoading,
  messages,
  person,
  withPhoto = false,
}: Props) => {
  if (withPhoto && !person) {
    throw new Error('Person is required for chat with photo');
  }

  return (
    <>
      {messages.map((message, i) => {
        return !withPhoto || message.turn === 'user' || !person ? (
          <SimpleMessage
            message={message}
            key={message.message + i + 'simple'}
          />
        ) : (
          <MessageWithPhoto
            message={message}
            person={person}
            key={message.message + i + 'photo'}
          />
        );
      })}
      {isLoading && (
        <div className="chat__message chat__message_bot chat__message_bot_typing">
          <div className="dot-flashing"></div>
        </div>
      )}
    </>
  );
};
