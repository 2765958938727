import {ArrowBackIosRounded} from '@mui/icons-material';
import {IconButton} from '@mui/material';
import {useEffect, useRef, useState} from 'react';
import {ChatMain} from '../../../../common-lib/src/components/ChatCommon/ChatMain/ChatMain';
import './MobileBotPage.css';
import sendImg from './images/send.svg';
import {ProfileModal} from './ProfileModal/ProfileModal';
import {SmartReplies} from '../BotChat/SmartReplies';
import infoImg from './images/info.svg';
import {useChat} from '../BotChat/useChat';
import {ProfInfo} from '../../../ProfTile/ProfTile';

type Props = {
  bot: ProfInfo;
  isLoading: boolean;
  useChatValue: ReturnType<typeof useChat>;
};

const headerHeight = 64;
const inputInitialHeight = 24;
const magicConst = 96;
let isInitResize = true;

export const MobileBotPage = ({bot, useChatValue}: Props) => {
  const {
    handleSmartReplyClick,
    focusInput,
    handleSendMessage,
    context,
    isLoading,
    inputValue,
    smartReplies,
    setInputValue,
  } = useChatValue;
  const [isProfileOpened, setIsProfileOpened] = useState(false);

  const chatRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const [inputHeight, setInputHeight] = useState(inputInitialHeight);
  const [chatHeight, setChatHeight] = useState(
    window.innerHeight - headerHeight - inputInitialHeight - magicConst
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    if (inputRef.current) {
      setInputHeight(inputRef.current.scrollHeight);
    }
  }, [inputRef.current?.scrollHeight]);

  useEffect(() => {
    if (
      !chatRef.current ||
      context.length < 2 ||
      chatRef.current.style.display === 'block'
    ) {
      return;
    }
    const messageEl = chatRef.current.firstChild as HTMLElement;
    const magicValue = 140;
    const type = messageEl.offsetTop < magicValue ? 'block' : 'flex';
    chatRef.current.style.display = type;
  }, [context, smartReplies]);

  const scrollChat = () => {
    if (chatRef.current) {
      chatRef.current.scrollTo(0, chatRef.current.scrollHeight);
    }
  };

  useEffect(() => {
    if (chatRef?.current) {
      scrollChat();
      focusInput();
    }
  }, [isLoading, context, smartReplies]);

  useEffect(() => {
    if (inputValue === '') {
      setInputHeight(inputInitialHeight);
    }
  }, [inputValue]);

  useEffect(() => {
    const secondMagicConst = isInitResize ? 0 : 0; //TODO: check
    setChatHeight(
      window.innerHeight -
        headerHeight -
        inputHeight -
        magicConst +
        secondMagicConst
    );
    isInitResize = false;
  }, [inputHeight]);

  useEffect(() => {
    const handleResize = () => {
      setChatHeight(
        window.innerHeight - headerHeight - inputHeight - magicConst
      );
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [inputHeight]);

  useEffect(() => {
    if (inputRef.current) {
      setInputHeight(inputRef.current.scrollHeight);
    }
  }, [inputRef.current?.scrollHeight]);

  const handleGoBack = () => {
    localStorage.setItem('isBack', 'true');
    window.location.href = '/';
  };

  const handleHeaderClick = () => {
    setIsProfileOpened(true);
  };

  return (
    <div className="mobile-bot-page">
      <div className="header">
        <IconButton sx={{padding: '0'}} className="back" onClick={handleGoBack}>
          <ArrowBackIosRounded
            sx={{
              color: 'var(--main-color)',
            }}
          />
        </IconButton>
        <div className="profile" onClick={handleHeaderClick}>
          <img src={bot.src} alt="avatar" className="avatar" />
          <div className="texts">
            <h2 className="name">{bot.name}</h2>
          </div>
        </div>
        <img
          src={infoImg}
          alt="Info"
          className="info"
          onClick={handleHeaderClick}
        />
      </div>
      <div className="warning">
        AI specialists’ answers are for informational purposes only
      </div>
      <div
        className="content"
        style={{height: `${chatHeight}px`}}
        ref={chatRef}
      >
        <ChatMain isLoading={isLoading} messages={context} />
        {!!smartReplies.length && (
          <SmartReplies
            messages={smartReplies}
            onClick={handleSmartReplyClick}
          />
        )}
      </div>
      <div className="input-field">
        <textarea
          ref={inputRef}
          name="input"
          id="input"
          className="input"
          style={{height: `${inputHeight}px`}}
          onChange={handleInputChange}
          value={inputValue}
          placeholder="Type here..."
          onFocus={scrollChat}
        />
        <img
          src={sendImg}
          alt="send"
          className="send"
          onClick={() => handleSendMessage(inputValue)}
        />
      </div>
      <ProfileModal
        bot={bot}
        isOpened={isProfileOpened}
        onClose={() => setIsProfileOpened(false)}
      />
    </div>
  );
};
