import cn from 'classnames';
import './CategorySelect.css';
import {useEffect, useState} from 'react';
import {useDisplaySize} from '../../../common-lib/src/hooks/useDisplaySize';
import arrowImg from './images/arrow.svg';

type Props = {
  tags: string[];
  selected: string;
  onChange: (tag: string) => void;
  className?: string;
};

export const CategorySelect = (props: Props) => {
  const [opened, setOpened] = useState(false);
  const {width} = useDisplaySize();

  const handleClose = (e: MouseEvent) => {
    if (
      e.target instanceof HTMLElement &&
      !e.target.closest('.category-select')
    ) {
      setOpened(false);
      document.removeEventListener('click', handleClose);
    }
  };

  const handleOpen = () => {
    setOpened(!opened);

    if (!opened) {
      document.addEventListener('click', handleClose);
    }
  };

  useEffect(() => {
    return () => {
      document.removeEventListener('click', handleClose);
    };
  }, []);

  return (
    <div
      className={cn('category-select', props.className)}
      onClick={() => handleOpen()}
    >
      <div className="category-select__header">
        {width > 630 && <p>{props.selected}</p>}
        <img
          className={cn('category-select__open-img', {
            'category-select__open-img_openned': opened,
          })}
          src={arrowImg}
          alt="open"
        />
      </div>
      <div
        className={cn('category-select__inner', {
          'category-select__inner_opened': opened,
        })}
      >
        {props.tags.map(tag => (
          <div
            key={tag}
            className={cn('category-select__item', {
              'category-select__item_selected': tag === props.selected,
            })}
            onClick={() => {
              props.onChange(tag);
              setOpened(false);
              document.removeEventListener('click', handleClose);
            }}
          >
            {tag}
            {props.selected === tag && (
              <p className="category-select__check">✓</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
