import {useCallback, useEffect, useState} from 'react';
import {Message} from '../../../../common-lib/src/api/Api';
import {MAX_MESSAGES_PER_USER, profApi} from '../../../../profApi/ProfApi';
import {chatStorage} from '../../../../chatStore/ChatStorage';
import {profAmplitudeApi} from '../../../../ampli/amplitudeApi';
import {useSnackbar} from 'notistack';
import {UserData} from '../../../App/App';
import {CookieHandler} from '../../../../profApi/CookieHandler';

let controller = new AbortController();
let signal = controller.signal;

let smartRepliesController = new AbortController();
let smartRepliesSignal = smartRepliesController.signal;

interface ChatBot {
  id: string;
  name: string;
}

export const useChat = (
  bot: ChatBot | null,
  userInfo: UserData | null,
  onLimitExceeded: () => void,
  isMainChat = false
) => {
  const [context, setContext] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [inputValue, setInputValue] = useState<string>(
    localStorage.getItem('question') || ''
  );
  const [smartReplies, setSmartReplies] = useState<string[]>([]);
  const {enqueueSnackbar} = useSnackbar();

  useEffect(() => {
    localStorage.setItem('question', '');
  }, []);

  const fetchInitChat = useCallback(async () => {
    if (!bot) {
      return;
    }
    setSmartReplies([]);
    try {
      let savedContext = chatStorage.get(bot.id);
      if (savedContext && savedContext.length) {
        savedContext = savedContext.filter((m: any) => !!m?.message?.trim());
      }
      if (savedContext && savedContext.length) {
        setContext(savedContext);
        if (
          savedContext.length &&
          savedContext[savedContext.length - 1].turn === 'bot'
        ) {
          return;
        }
        try {
          const message = await fetchRespone(savedContext, true);
          const newContext = [...savedContext, message];
          setContext(newContext);
          focusInput();
        } catch (e) {
          console.error(e);
        }
        return;
      }

      const message = await fetchRespone([], true);
      setContext([message]);
      focusInput();
    } catch (err: any) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, [bot]);

  const focusInput = (withScroll = true) => {
    const inputEl = document.getElementById('chat-input');
    // if (withScroll) {
    // 	inputEl?.scrollIntoView({behavior: 'auto', block: 'end'});
    // }
    inputEl?.focus({preventScroll: true});
  };

  const fetchRespone = async (contextForRequest: Message[], isInit = false) => {
    if (!bot) {
      throw new Error('Bot is not defined');
    }
    const mesQty = CookieHandler.getMessagesCounter();

    if (!userInfo?.paid && mesQty >= MAX_MESSAGES_PER_USER) {
      onLimitExceeded();

      throw new Error('Limit exceeded');
    }
    try {
      setIsLoading(true);

      controller.abort();
      controller = new AbortController();
      signal = controller.signal;

      smartRepliesController.abort();
      smartRepliesController = new AbortController();
      smartRepliesSignal = smartRepliesController.signal;

      const res = await profApi.chat(
        bot.name,
        contextForRequest,
        signal,
        isInit
      );

      if (!res) {
        enqueueSnackbar('Internal error. Please try again', {
          variant: 'error',
          transitionDuration: 3000,
        });
        throw new Error('Empty response');
      }

      const message: Message = {message: res, turn: 'bot'};

      return message;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendMessage = async (message: string, fromSR = false) => {
    if (isLoading || !message.trim() || !bot) {
      return;
    }

    profAmplitudeApi.userSentMessage(
      bot.id,
      fromSR ? 'smart_reply' : 'user',
      isMainChat ? 'chat' : undefined
    );

    setInputValue('');
    setSmartReplies([]);

    const copy = context.slice();
    const contextForRequest = context.slice(-9);
    const newMessage = {message, turn: 'user'};
    const newContext = [...copy, {message, turn: 'user'}];
    contextForRequest.push(newMessage);
    setContext(newContext);

    try {
      localStorage.setItem('question', message || '');
      const botMessage = await fetchRespone(contextForRequest);
      localStorage.setItem('question', '');

      newContext.push(botMessage);

      setContext([...newContext]);
      focusInput();
    } catch (error: any) {
      setContext(copy);
      console.error(error);
    }
  };

  const handleSmartReplyClick = (message: string) => {
    setSmartReplies([]);
    handleSendMessage(message, true);
  };

  useEffect(() => {
    if (!bot) {
      return;
    }
    if (context.length) {
      chatStorage.set(bot.id, context);
    }

    if (context[context.length - 1]?.turn === 'bot') {
      if (
        !userInfo?.paid &&
        CookieHandler.getMessagesCounter() >= MAX_MESSAGES_PER_USER
      ) {
        return;
      }
      smartRepliesController.abort();
      smartRepliesController = new AbortController();
      smartRepliesSignal = smartRepliesController.signal;

      profApi
        .smartReplies(bot.name, context, smartRepliesSignal)
        .then(res => {
          setSmartReplies(res);
        })
        .catch(err => {
          setSmartReplies([]);
          console.error(err);
        });
    }
  }, [context]);

  useEffect(() => {
    setContext([]);
    fetchInitChat();
  }, [bot]);

  return {
    handleSmartReplyClick,
    focusInput,
    handleSendMessage,
    fetchInitChat,
    context,
    isLoading,
    inputValue,
    smartReplies,
    setInputValue,
  };
};
