import {useState} from 'react';
import {InButton} from '../../common-lib/src/components/InButton/InButton';
import {InModal} from '../../common-lib/src/components/InModal/InModal';
import {TextArea} from '../../common-lib/src/components/TextArea/TextArea';
import './FeedbackModal.css';
import sendImg from './images/send.svg';

type Props = {
  openned: boolean;
  onClose: () => void;
  onSubmit: (value: string) => void;
};

export const FeedbackModal = ({openned, onClose, onSubmit}: Props) => {
  const [value, setValue] = useState('');
  const handleChange = (value: string) => {
    setValue(value);
  };

  const handleClose = () => {
    setValue('');
    onClose();
  };

  const handleSubmit = () => {
    onSubmit(value);
    setValue('');
  };

  return (
    <InModal
      openned={openned}
      onClose={handleClose}
      className="feedback-modal"
      skipInvertion
    >
      <div className="feedback-modal__title">Leave feedback</div>
      <TextArea
        placeholder="Type or paste here…"
        value={value}
        onChange={handleChange}
        className="feedback-text"
      />
      <InButton
        isDisabled={!value}
        isFilled
        onClick={handleSubmit}
        id="feedback-send"
        className="feedback-send"
      >
        <img src={sendImg} alt="send-btn" />
        <p className="feedback-send__text">Send</p>
      </InButton>
    </InModal>
  );
};
