import { ProfInfo } from '../components/ProfTile/ProfTile';
import { profsData } from './constants';

export const getProfDescription = (name: string): string => {
	return `Did you think you could just barge into my castle? Either explain yourself or off with your head!`;
};

export const getBot = (botId?: string): ProfInfo => {
	const PROFS = Object.values(profsData);

	let prof = PROFS.find((prof) => prof.id === botId);
	if (!prof) {
		prof = PROFS[0];
		console.error(`Bot with id ${botId} not found`);
	}

	return {
		name: prof.role,
		author: 'N/A',
		description: prof.description,
		src: prof.photo,
		idleUrl: '',
		messagesQty: Math.floor(Math.random() * 10000),
		likes: Math.floor(Math.random() * 100),
		id: prof.id,
		withLipSync: false,
		greeting: prof.greeting,
		tags: prof.tags,
		questions: prof.questions,
		isPremium: !!prof.isPremium,
	};
};

export const getBots = (): ProfInfo[] => {
	const PROFS = Object.values(profsData);
	return PROFS.map((prof) => getBot(prof.id));
};

export const getRandomBots = (count: number, except?: string): ProfInfo[] => {
	return Object.values(profsData)
		.filter((prof) => prof.id !== except)
		.sort(() => 0.5 - Math.random())
		.slice(0, count)
		.map((prof) => getBot(prof.id));
};

export const isMobile = () => {
	const isMobile =
		/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
			navigator.userAgent
		);
	const isMobileWidht = window.innerWidth < 768;
	return isMobile || isMobileWidht;
};
