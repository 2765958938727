import {useDisplaySize} from '../../../../common-lib/src/hooks/useDisplaySize';

type SmartRepliesProps = {
  messages: string[];
  onClick: (message: string) => void;
};

export const SmartReplies = ({messages, onClick}: SmartRepliesProps) => {
  const {width} = useDisplaySize();

  const splitMessage = (message: string, maxSymbolsInRow: number) => {
    const words = message.split(' ');
    const rows: string[] = [];
    let row = '';
    words.forEach(word => {
      if (row.length + word.length + 1 > maxSymbolsInRow) {
        rows.push(row);
        row = '';
      }
      row += `${word} `;
    });
    rows.push(row);
    return rows;
  };

  const splitMessageToRows = (message: string) => {
    const messageLenght = message.length;
    const maxSymbols = Math.fround(window.innerWidth / 11);

    if (messageLenght > maxSymbols) {
      return splitMessage(message, maxSymbols);
    }
    return [message];
  };

  return (
    <div
      className={`smart-replies__row ${
        messages.length === 1 ? 'smart-replies__row_single' : ''
      }`}
    >
      {messages.map((message, i) => {
        const rows = splitMessageToRows(message);
        return (
          <div
            className="reply"
            key={i}
            onClick={() => onClick(message)}
            id={`smart-reply-${i + 1}`}
          >
            {width > 500 ? (
              message
            ) : (
              <>
                {rows?.map((row, i) => (
                  <span key={i}>{row}</span>
                ))}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};
