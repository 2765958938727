import {useEffect, useState} from 'react';

const MOBILE_WIDTH = 630;
type Size = {
  width: number;
  height: number;
};

export const useDisplaySize = () => {
  const [windowSize, setWindowSize] = useState<Size>({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({width: window.innerWidth, height: window.innerHeight});
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return {
    width: windowSize.width,
    height: windowSize.height,
    isMobile: windowSize.width <= MOBILE_WIDTH,
  };
};
