import {createContext, useCallback, useEffect, useState} from 'react';
import {SnackbarProvider, enqueueSnackbar} from 'notistack';
import {Route, Routes, useNavigate} from 'react-router-dom';
import {URLS} from '../../common/constants';
import {BotPage} from '../BotPage/BotPage';
import {Main} from '../Main/Main';
import './App.css';
import {profAmplitudeApi} from '../../ampli/amplitudeApi';
import {profApi} from '../../profApi/ProfApi';
import {CookieHandler} from '../../profApi/CookieHandler';
import {Spinner} from '../../common-lib/src/components/Spinner/Spinner';
import {chatStorage} from '../../chatStore/ChatStorage';
import {PaymentStatus} from '../../profApi/ProfApi.types';
import {SubscriptionPage} from '../SubsciptionPage/SubscriptionPage';
import {SuccessPayment} from '../SuccessPayment/SuccessPayment';
import {LimitReachedModal} from '../LimitReachedModal/LimitReachedModal';
import {useAuth0} from '@auth0/auth0-react';
import {Verify} from '../../common-lib/src/components/Verify/Verify';

export type UserData = {
  token: string;
  email: string;
  paid: boolean;
};

export type MainContextType = {
  userInfo: UserData | null;
  isUserInfoLoading: boolean;
  setUserInfo: (userInfo: UserData | null) => void;
  loginAndSubscribe: () => void;
  openLoginModal: () => void;
  refreshUserInfo: () => Promise<void>;
};

export const MainContext = createContext<MainContextType>({
  userInfo: null,
  isUserInfoLoading: false,
  setUserInfo: (userInfo: UserData | null) => {},
  openLoginModal: () => {},
  loginAndSubscribe: () => {},
  refreshUserInfo: () => Promise.resolve(),
});

function App() {
  const [userInfo, setUserInfo] = useState<UserData | null>(null);
  const {
    getAccessTokenSilently,
    loginWithRedirect,
    user,
    logout,
    isLoading: authLoading,
  } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const [isLimitReachedModalOpen, setIsLimitReachedModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleSetUserInfo = (userData: UserData | null) => {
    setUserInfo(userData);
    if (!userData) {
      chatStorage.removeUserData();
      return;
    }
    chatStorage.setUserData(userData);
  };

  const fetchAuthUser = async () => {
    try {
      if (!user?.email) {
        enqueueSnackbar('No email provided', {
          variant: 'error',
        });
        logout({logoutParams: {returnTo: window.location.origin}});
        return;
      }

      if (!user?.email_verified) {
        logout({logoutParams: {returnTo: window.location.origin + '/verify'}});
        return;
      }
      setIsLoading(true);
      const token = await getAccessTokenSilently();
      const email = user?.email || '';
      const fbc = CookieHandler.getFbc();
      const ga = CookieHandler.getGA();
      const gclid = CookieHandler.getGclidFromCookies();

      const result = await profApi.loginAuth({token, email, fbc, ga, gclid});
      if (!result) {
        enqueueSnackbar('Login error', {
          variant: 'error',
        });
        return;
      }

      CookieHandler.setTokenCookie(result.token);
      await fetchUserInfo(true);

      if (location?.hash) {
        const hash = location.hash.replace('#', '');
        if (hash === 'login') {
          window.history.pushState({}, '', '/');
        }
      }

      const afterLogin = localStorage.getItem('afterLogin');
      if (afterLogin === 'subscription') {
        if (result.isNew) {
          navigate(`${URLS.subscription}/?new=true`);
        } else {
          navigate(URLS.subscription);
        }
      }
      if (result.isNew) {
        navigate(`/?new=true`);
      }
    } catch (e) {
      console.log(e);
      enqueueSnackbar('Login error', {variant: 'error'});
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!user) {
      setUserInfo(null);
    } else {
      fetchAuthUser();
    }
  }, [user]);

  const handleLoginOpen = async () => {
    if (!user) {
      await loginWithRedirect();
    } else {
      fetchAuthUser();
    }

    window.history.pushState({}, '', '/#login');
  };

  const showLimitReachedModal = () => {
    setIsLimitReachedModalOpen(true);
  };

  const handleLoginAndSubscribe = (isLocked: boolean) => {
    if (isLocked) {
      profAmplitudeApi.setIsUnlock();
    } else {
      profAmplitudeApi.setIsLimit();
    }
    setIsLimitReachedModalOpen(false);
    if (!userInfo) {
      localStorage.setItem('afterLogin', 'subscription');
      handleLoginOpen();
    } else {
      window.location.href = URLS.subscription;
    }
  };

  const logAmplitude = () => {
    profAmplitudeApi.setUserProps();
  };

  const getParameterByName = (name: string) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
  };

  useEffect(() => {
    const fbFn = () => {
      const fbclid = getParameterByName('fbclid');
      if (!fbclid) {
        return;
      }
      const fbc = `fb.1.${Date.now()}.${fbclid}`;
      localStorage.setItem('_fbc', fbc);
    };

    const gclFn = () => {
      const gclid = getParameterByName('gclid');
      if (!gclid) {
        return;
      }

      localStorage.setItem(CookieHandler.GCLID, gclid);
      CookieHandler.setGclidCookie(gclid);
    };

    gclFn();
    fbFn();
  }, []);

  const fetchUserInfo = useCallback(async (noLoader = false) => {
    const token = CookieHandler.getTokenFromCookies();
    if (!token || (!user && !authLoading)) {
      setIsLoading(false);
      return;
    }

    if (!noLoader) {
      setIsLoading(true);
    }
    const {userInfo} = await profApi.getUserInfo();
    const userData: UserData = {
      token: userInfo.api_key,
      email: userInfo.email,
      paid: userInfo.paymentStatus === PaymentStatus.paid,
    };
    if (userInfo?.api_key && userData.paid) {
      profApi.setToken(userInfo.api_key);
    }
    chatStorage.setUserData(userData);
    if (!noLoader) {
      setIsLoading(false);
    }
    setUserInfo(userData);
    return;
  }, []);

  const context = {
    userInfo,
    isUserInfoLoading: isLoading || authLoading,
    setUserInfo: handleSetUserInfo,
    openLoginModal: handleLoginOpen,
    loginAndSubscribe: showLimitReachedModal,
    refreshUserInfo: fetchUserInfo,
  };

  useEffect(() => {
    fetchUserInfo();

    localStorage.setItem('category', '');
    logAmplitude();

    if (!localStorage.getItem('userId')) {
      localStorage.setItem(
        'userId',
        Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15)
      );
    }

    if (location?.hash) {
      const hash = location.hash.replace('#', '');
      if (hash === 'login') {
        handleLoginOpen();
      }
    }
  }, []);

  return (
    <div className="App">
      <SnackbarProvider maxSnack={3} autoHideDuration={1500}>
        <MainContext.Provider value={context}>
          <Routes>
            <Route
              path={URLS.main}
              element={
                <Main
                  onLogin={handleLoginOpen}
                  loading={isLoading || authLoading}
                />
              }
            />
            <Route path={`${URLS.bot}/:botId`} element={<BotPage />} />
            <Route
              path={URLS.subscription}
              element={
                <SubscriptionPage
                  refreshUserInfo={fetchUserInfo}
                  loading={isLoading || authLoading}
                />
              }
            />
            <Route path={URLS.paySuccess} element={<SuccessPayment />} />
            <Route path="/verify" element={<Verify />} />
            <Route path="*" element={<div>Page not found</div>} />
          </Routes>
          <LimitReachedModal
            openned={isLimitReachedModalOpen}
            onClose={() => setIsLimitReachedModalOpen(false)}
            onSuccess={handleLoginAndSubscribe}
          />
        </MainContext.Provider>
        {(isLoading || authLoading) && <Spinner withLayout />}
      </SnackbarProvider>
    </div>
  );
}

export default App;
