import {useState, useEffect} from 'react';
import './VideoAvatar.css';
import {useDisplaySize} from '../../hooks/useDisplaySize';

type AvatarProps = {
  src: string;
  defaultSrc: string;
  isDefault: boolean;
  onEnd: () => void;
  isInitial: boolean;
  onClick?: () => void;
  mobileLipsync?: boolean;
};

export const VideoAvatar = ({
  src,
  defaultSrc,
  isDefault,
  onEnd,
  isInitial,
  onClick = () => {},
  mobileLipsync = false,
}: AvatarProps) => {
  const [showDefault, setShowDefault] = useState(src === defaultSrc);
  const {isMobile} = useDisplaySize();

  useEffect(() => {
    setShowDefault(true);
  }, [src]);

  return (
    <>
      <video
        autoPlay
        muted
        loop={isDefault}
        playsInline
        className={`${
          !showDefault ? 'hidden' : ''
        } chat__avatar chat__avatar_top`}
        key={defaultSrc + 'prev'}
        onClick={onClick}
      >
        <source src={defaultSrc} type="video/mp4" key={defaultSrc} />
      </video>
      <video
        autoPlay
        muted={isInitial || isDefault || (isMobile && !mobileLipsync)}
        loop={isDefault}
        playsInline
        className={`${
          showDefault ? 'hidden' : ''
        } chat__avatar chat__avatar_top`}
        key={src}
        onEnded={() => {
          if (!isDefault || isInitial) {
            onEnd();
            setShowDefault(true);
          }
        }}
        onLoadedData={() => {
          setShowDefault(false);
        }}
        onClick={onClick}
      >
        <source src={src} type="video/mp4" key={src} />
      </video>
    </>
  );
};
