import {chatStorage} from '../chatStore/ChatStorage';
import {Message} from '../common-lib/src/api/Api';
import {URLS, getEnvConfig} from '../common/constants';
import {isMobile} from '../common/utils';
import {CookieHandler} from './CookieHandler';
import {
  InternalEventData,
  LoginGoogleResponse,
  PaymentPlan,
  UserPaymentInfo,
} from './ProfApi.types';

const ROOT_URL = 'https://api.exh.ai';
const DEFAULT_TOKEN =
  'eyJhbGciOiJIUzUxMiJ9.eyJ1c2VybmFtZSI6IndlYi1tb2JpbGUifQ.N-tSiifBluUXlnESKQbD4mwQtaCfWwJJ-RXiXO7rAKktx3yLTAFw7hqm5MLZUiaMgERzjY9QwsRVf3T8C8HSTw';
const USER_API_ROOT_URL = getEnvConfig().api;
export const MAX_MESSAGES_PER_USER = 5;

class ProfApi {
  url: string;
  token: string | null;

  constructor() {
    this.url = `${ROOT_URL}/ai_professionals/v2`;
    this.token = DEFAULT_TOKEN;
  }

  getFromIndexDb(key: string) {
    return new Promise(resolve => {
      const request = indexedDB.open('exh', 1);
      request.onsuccess = (event: any) => {
        const db = event.target.result;
        const transaction = db.transaction(['data'], 'readonly');
        const objectStore = transaction.objectStore('data');
        const request = objectStore.get(key);
        request.onsuccess = (event: any) => {
          resolve(event.target.result);
        };
      };
    });
  }

  private getUserId = () => {
    const userId = localStorage.getItem('userId');
    if (!userId) {
      const newUserId =
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);
      localStorage.setItem('userId', newUserId);
      return newUserId;
    }
    return userId;
  };

  setToken = (token: string) => {
    this.token = token;
  };

  resetToken = () => {
    this.setToken(DEFAULT_TOKEN);
  };

  chat = async (
    name: string,
    context: Message[],
    signal?: AbortSignal,
    skipLimit = false
  ) => {
    const response = await fetch(`${this.url}/get_response`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify({
        professional_title: name,
        context: this.prepareContext(context),
        user_id: this.getUserId(),
      }),
      signal,
    });
    if (response.ok && !skipLimit) {
      CookieHandler.increaseMessagesCounter();
    }
    return response.json().then(res => {
      return res.response;
    });
  };

  private prepareContext(context: Message[]) {
    return context
      .filter(({message, turn}) => !!message && !!turn)
      .map(({message, turn}) => ({message, turn}))
      .slice(-6);
  }

  smartReplies = async (
    name: string,
    context: Message[],
    signal?: AbortSignal
  ): Promise<string[]> => {
    const response = await fetch(`${this.url}/get_smart_replies`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify({
        professional_title: name,
        context: this.prepareContext(context),
        user_id: this.getUserId(),
      }),
      signal,
    });
    return response.json().then(res => res.responses || []);
  };

  feedback = async (bot_name: string, text: string): Promise<void> => {
    const timestamp = new Date().toISOString();
    const source = isMobile() ? 'web-mobile/ai-pro' : 'web/ai-pro';
    const response = await fetch(`${this.url}/save_feedback`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify({
        bot_name,
        feedback: text,
        source,
        user_id: this.getUserId(),
        timestamp,
      }),
    });
    return response.json();
  };

  async loginAuth(data: {
    email: string;
    token: string;
    fbc?: string;
    ga?: string;
    gclid?: string;
  }): Promise<LoginGoogleResponse | null> {
    const url = `${USER_API_ROOT_URL}/user/login/auth`;
    const body = JSON.stringify({
      email: data.email,
      fbc: data.fbc || '',
      ga: data.ga || '',
      gclid: data.gclid || '',
    });

    const result = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${data.token}`,
      },
      body,
    }).then(res => (res.ok ? res.json() : null));

    return result as LoginGoogleResponse;
  }

  async loginGoogle(data: {
    email: string;
    token: string;
    fbc?: string;
    ga?: string;
    gclid?: string;
  }): Promise<LoginGoogleResponse | null> {
    const url = `${USER_API_ROOT_URL}/user/login/google`;
    const body = JSON.stringify({
      email: data.email,
      googleId: data.token,
      fbc: data.fbc || '',
      ga: data.ga || '',
      gclid: data.gclid || '',
    });

    const result = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    }).then(res => (res.ok ? res.json() : null));

    return result as {user: any; token: string};
  }

  async loginFacebook(data: {
    email: string;
    token: string;
    fbc?: string;
  }): Promise<LoginGoogleResponse | null> {
    const url = `${USER_API_ROOT_URL}/user/login/facebook`;
    const body = JSON.stringify({
      email: data.email,
      token: data.token,
      fbc: data.fbc || '',
    });

    const result = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    }).then(res => (res.ok ? res.json() : null));

    return result as {user: any; token: string};
  }

  catchUnathorized = (response: Response) => {
    if (response.status === 403) {
      CookieHandler.removeTokenCookie();
      this.resetToken();
      chatStorage.removeUserData();
      window.location.href = URLS.main;
    }
    return response;
  };

  async getUserInfo() {
    const url = `${USER_API_ROOT_URL}/user/me`;
    const token = CookieHandler.getTokenFromCookies();
    if (!token) {
      return null;
    }
    const result = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then(this.catchUnathorized)
      .then(res => (res.ok ? res.json() : null));
    return result;
  }

  async getSubscriptionInfo(): Promise<{
    paymentInfo: UserPaymentInfo | null;
  }> {
    const url = `${USER_API_ROOT_URL}/payment/info`;
    const token = CookieHandler.getTokenFromCookies();

    if (!token) {
      return {paymentInfo: null};
    }
    const result = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then(this.catchUnathorized)
      .then(res => (res.ok ? res.json() : {paymentInfo: null}));
    return result;
  }

  async subscriptionCheckout(plan: PaymentPlan) {
    const url = `${USER_API_ROOT_URL}/payment/subscription`;
    const token = CookieHandler.getTokenFromCookies();

    if (!token) {
      throw new Error('No token');
    }
    const result = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({plan}),
    })
      .then(this.catchUnathorized)
      .then(res =>
        res.ok ? res.json() : {error: res.statusText, code: res.status}
      );

    if (result.error) {
      throw new Error(result.error);
    }

    return result as {url: string};
  }

  async subscriptionCancel() {
    const url = `${USER_API_ROOT_URL}/payment/subscription`;
    const token = CookieHandler.getTokenFromCookies();

    if (!token) {
      throw new Error('No token');
    }
    const result = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then(this.catchUnathorized)
      .then(res =>
        res.ok ? res.json() : {error: res.statusText, code: res.status}
      );

    if (result.error) {
      throw new Error(result.error);
    }

    return result as {url: string};
  }

  async chatNowEvent(chat_id: string) {
    const url = `${USER_API_ROOT_URL}/user/chatnow-event`;
    const token = CookieHandler.getTokenFromCookies();

    if (!token) {
      return;
    }
    const result = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({chat_id}),
    })
      .then(this.catchUnathorized)
      .then(res =>
        res.ok ? res.json() : {error: res.statusText, code: res.status}
      );

    if (result.error) {
      throw new Error(result.error);
    }

    return result as {success: boolean};
  }

  async internalEventLog(data: InternalEventData) {
    const url = `${USER_API_ROOT_URL}/evt/webhook`;

    try {
      await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `d5d0bc72-2718-11ee-be56-0242ac120002`,
        },
        body: JSON.stringify(data),
      });
    } catch (e) {
      console.error('internalEventLog', e);
    }
  }
}

export const profApi = new ProfApi();
