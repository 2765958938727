import {init, identify, Identify} from '@amplitude/analytics-browser';
import {isLocalEnv} from '../common-lib/src/common/utils';
import {AMPLITUDE_KEY} from './constants';
import {isMobile} from '../common/utils';
import {getCookie} from '../components/CookieBar/utils';
import {USE_COOKIES_CONSENT_KEY} from '../components/CookieBar/constants';
import {api} from '../common-lib/src/api/Api';
import {profApi} from '../profApi/ProfApi';
import {InternalEventData} from '../profApi/ProfApi.types';

class AmplitudeApi {
  isOn: boolean;
  userProps: any;
  subscribeActionInfo: {
    isUnlock: boolean;
    isLimit: boolean;
  };
  constructor() {
    this.init();
    this.isOn = !isLocalEnv();
    this.updateCookiesConsent();
    this.subscribeActionInfo = {
      isUnlock: false,
      isLimit: false,
    };
  }

  private init() {
    init(AMPLITUDE_KEY);
  }

  updateCookiesConsent() {
    const cookiesData = getCookie(USE_COOKIES_CONSENT_KEY);
    if (cookiesData) {
      this.isOn = cookiesData.isAgreed;
    }
    if (isLocalEnv()) {
      this.isOn = false;
    }
  }

  internalEventLog(name: string, eventProperties?: Record<string, string>) {
    const data: InternalEventData = {
      name,
      userProperties: {
        id: api.getUserId(),
        device_category: isMobile() ? 'mobile' : 'desktop',
      },
      eventProperties: eventProperties || {},
    };

    profApi.internalEventLog(data);
  }

  setUserProps() {
    return;
    const event = new Identify();

    event.set('device_category', isMobile() ? 'mobile' : 'desktop');
    event.set('Platform', 'Web');
    event.set('web_platform', 'Professionals');

    identify(event);
  }

  private event(name: string, data?: Record<string, string>) {
    // logEvent(name, data);
    this.internalEventLog(name, data);
  }

  chatVisit(
    id: string,
    categoryName = 'suggestions',
    source: string,
    type?: string
  ) {
    const data: Record<string, string> = {
      chat_id: id,
      category_name: categoryName,
      source,
    };
    if (type) {
      data.type = type;
    }
    this.event('open_chat_pressed', data);
  }

  userSentMessage(id: string, source: 'smart_reply' | 'user', type?: string) {
    const data: Record<string, string> = {
      chat_id: id,
      source,
    };
    if (type) {
      data.type = type;
    }

    this.event('Message_by_user', data);
  }

  goToCategoriesPresed(source: 'how_it_works' | 'why_use') {
    this.event('go_to_categories_pressed', {
      source,
    });
  }

  botReaction(id: string, source: 'like' | 'report') {
    this.event('like' ? 'bot_liked' : 'reported_bot', {
      chat_id: id,
      source,
    });
  }

  categoryPress(category_name: string, type?: string) {
    const data: Record<string, string> = {
      category_name,
    };
    if (type) {
      data.type = type;
    }
    this.event('category_pressed', data);
  }

  subscribePressed(plan: string) {
    let source = 'personal_account';
    const subscribeActionInfo = localStorage.getItem('subscribeActionInfo');
    if (
      this.subscribeActionInfo.isUnlock ||
      subscribeActionInfo === 'isUnlock'
    ) {
      source = 'pop_up_unlock';
    }
    if (this.subscribeActionInfo.isLimit || subscribeActionInfo === 'isLimit') {
      source = 'pop_up_limit';
    }
    localStorage.removeItem('subscribeActionInfo');
    this.event('Subscription_button_pressed', {plan, source});
  }

  subscriptionPaid() {
    this.event('Subscription_paid');
  }

  visit() {
    this.event('visit');
  }

  setIsUnlock() {
    localStorage.setItem('subscribeActionInfo', 'isUnlock');
    this.subscribeActionInfo.isUnlock = true;
    this.subscribeActionInfo.isLimit = false;
  }

  setIsLimit() {
    localStorage.setItem('subscribeActionInfo', 'isLimit');
    this.subscribeActionInfo.isUnlock = false;
    this.subscribeActionInfo.isLimit = true;
  }
}

export const profAmplitudeApi = new AmplitudeApi();
