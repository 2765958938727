import {InButton} from '../../../../common-lib/src/components/InButton/InButton';
import './LikeReport.css';
import like from './images/like.svg';
import report from './images/report.svg';
import liked from './images/liked.svg';
import {profAmplitudeApi} from '../../../../ampli/amplitudeApi';

type Props = {
  onLike: () => void;
  onReport: () => void;
  isLiked: boolean;
  botId: string;
};

export const LikeReport = ({onLike, onReport, isLiked, botId}: Props) => {
  const handleLike = () => {
    if (!isLiked) {
      profAmplitudeApi.botReaction(botId, 'like');
    }

    onLike();
  };

  const handleReport = () => {
    profAmplitudeApi.botReaction(botId, 'report');
    onReport();
  };
  return (
    <div className="like-report">
      <InButton
        id="like"
        isFilled={!isLiked}
        onClick={handleLike}
        className={
          isLiked
            ? 'like-report__btn like-report__btn--liked like-report__btn--like'
            : 'like-report__btn like-report__btn--like'
        }
      >
        <img
          src={isLiked ? liked : like}
          alt="like"
          className="like-report__icon"
        />
        {isLiked ? 'Liked' : 'Like'}
      </InButton>
      <InButton
        id="report"
        onClick={handleReport}
        className="like-report__btn like-report__btn--report"
      >
        <img src={report} alt="report" className="like-report__icon" />
        Report
      </InButton>
    </div>
  );
};
