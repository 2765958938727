import {Button} from '@mui/material';
import './InButton.css';

type Props = {
  isFilled?: boolean;
  isDisabled?: boolean;
  onClick?: (e?: any) => void;
  className?: string;
  children: string | React.ReactNode;
  light?: boolean;
  id: string;
  inverted?: boolean;
};

export const InButton = ({
  light = false,
  isFilled = false,
  isDisabled = false,
  children,
  onClick = () => {},
  className = '',
  id,
  inverted = false,
}: Props) => {
  const lightClass = light ? 'light' : '';
  return (
    <Button
      variant={`${isFilled ? 'contained' : 'outlined'}`}
      className={`inbutton ${className} ${lightClass} ${
        inverted ? 'inbutton_inverted' : ''
      }`}
      onClick={onClick}
      id={id}
      disabled={isDisabled}
    >
      {children}
    </Button>
  );
};
