import './OtherBotTile.css';
import {URLS} from '../../common/constants';
import {profAmplitudeApi} from '../../ampli/amplitudeApi';
import {ProfInfo} from '../ProfTile/ProfTile';
import lockImg from '../ProfTile/images/lock.svg';
import lockBtnImg from './images/btn-unlock.svg';
import {InButton} from '../../common-lib/src/components/InButton/InButton';

export const OtherBotTile = ({
  bot,
  paid,
  onUnlockClick,
}: {
  bot: ProfInfo;
  paid?: boolean;
  onUnlockClick: () => void;
}) => {
  const isLocked = !!(bot.isPremium && !paid);

  const handleClick = (e: any) => {
    e.preventDefault();
    profAmplitudeApi.chatVisit(bot.id, 'suggestions', 'suggestions');
    if (isLocked) {
      localStorage.setItem('unlock', 'unlock');
      onUnlockClick();
    } else {
      window.location.href = `${URLS.bot}/${bot.id}`;
    }
  };

  return (
    <a
      href={`${URLS.bot}/${bot.id}`}
      className={`other-bot-tile ${isLocked ? 'other-bot-tile__locked' : ''}`}
      onClick={handleClick}
    >
      <img
        src={bot.src}
        alt="professional avatar"
        className="other-bot-tile__image"
      />
      <div className="other-bot-tile__name">{bot.name}</div>
      {isLocked && (
        <>
          <img
            src={lockBtnImg}
            alt="unlock"
            className="ohter-bot-tile__lock-img"
          />
          <InButton
            id="unlock"
            className="ohter-bot-tile__lock-btn"
            onClick={onUnlockClick}
          >
            <img src={lockImg} alt="lock" className="lock-img" />
            <p>Unlock</p>
          </InButton>
        </>
      )}
    </a>
  );
};
