import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/App/App';
import {Auth0Provider} from '@auth0/auth0-react';
import {BrowserRouter} from 'react-router-dom';
import {profAmplitudeApi} from './ampli/amplitudeApi';
import {CookieHandler} from './profApi/CookieHandler';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

profAmplitudeApi.visit();
CookieHandler.cfidtoc();

root.render(
  <Auth0Provider
    domain="auth.exh.ai"
    clientId="2O06utNg5npUvGKFRAZlW17Mq5et9MbS"
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: 'https://dev-s1xp2abkl4fpw1dn.us.auth0.com/api/v2/',
    }}
  >
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Auth0Provider>
);
