import './Chat.css';

type Props = {
  children: React.ReactNode;
  className?: string;
};

export const ChatContainer = ({className, children}: Props) => {
  return (
    <div className={`chat-block__chat ${className || ''}`} id="chat">
      {children}
    </div>
  );
};
