import {Modal} from '@mui/material';
import {CloseModalBtn} from '../CloseModalBtn/CloseModalBtn';
import './InModal.css';

type Props = {
  openned: boolean;
  onClose: () => void;
  children: React.ReactNode;
  className?: string;
  skipInvertion?: boolean;
};

export const InModal = ({
  children,
  openned,
  onClose,
  className = '',
  skipInvertion = false,
}: Props) => {
  return (
    <Modal className={`in-modal ${className}`} open={openned} onClose={onClose}>
      <div className={`in-modal__inner ${className}__inner`}>
        {children}
        <CloseModalBtn onClose={onClose} skipInvertion={skipInvertion} />
      </div>
    </Modal>
  );
};
