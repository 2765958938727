import { UserData } from '../components/App/App';

const MAX_MESSAGE = 20;
export const RESTORE_TIME_MIN = 120;

class ChatStorage {
	chatStorage: Record<string, any>;
	visitedStorage: string[];
	firstMessageStart: Date | null;
	messageCount: number;
	liked: Record<string, boolean>;
	userData: UserData | null = null;
	constructor() {
		this.chatStorage = {};
		this.visitedStorage = [];
		this.firstMessageStart = null;
		this.messageCount = 0;
		this.liked = {};

		this.load();
	}

	load() {
		const data = localStorage.getItem('chat');
		if (data) {
			this.chatStorage = JSON.parse(data);
		}

		const visited = localStorage.getItem('visited');
		if (visited) {
			this.visitedStorage = JSON.parse(visited);
		}

		const fmt = localStorage.getItem('fmt');
		if (fmt) {
			this.firstMessageStart = new Date(fmt);
		}

		const count = localStorage.getItem('count');
		if (count) {
			this.messageCount = parseInt(count);
		}

		const liked = localStorage.getItem('liked');
		if (liked) {
			this.liked = JSON.parse(liked);
		}

		const userData = localStorage.getItem('userData');
		if (userData) {
			this.userData = JSON.parse(userData);
		}
	}

	save() {
		localStorage.setItem('chat', JSON.stringify(this.chatStorage));
		localStorage.setItem('visited', JSON.stringify(this.visitedStorage));
		if (this.firstMessageStart) {
			localStorage.setItem('fmt', this.firstMessageStart.toISOString());
		}
		localStorage.setItem('count', this.messageCount.toString());
		localStorage.setItem('liked', JSON.stringify(this.liked));
		if (this.userData) {
			localStorage.setItem('userData', JSON.stringify(this.userData));
		} else {
			localStorage.removeItem('userData');
		}
	}

	get(key: string) {
		return this.chatStorage[key];
	}

	getKeys() {
		return Object.keys(this.chatStorage);
	}

	set(key: string, value: any) {
		this.chatStorage[key] = value;
		this.save();
	}

	getChatVisited() {
		return this.visitedStorage;
	}

	setChatVisited(id: string) {
		if (this.visitedStorage.includes(id)) {
			return;
		}
		this.visitedStorage.push(id);
		this.save();
	}

	isChatVisited(id: string) {
		return this.visitedStorage.includes(id);
	}

	like(id: string) {
		this.liked[id] = true;
		this.save();
	}

	unlike(id: string) {
		this.liked[id] = false;
		this.save();
	}

	isLiked(id: string) {
		return this.liked[id] === true;
	}

	getLiked() {
		return Object.keys(this.liked).filter(
			(key) => this.liked[key] === true
		);
	}

	message() {
		this.messageCount++;
		if (!this.firstMessageStart) {
			this.firstMessageStart = new Date();
			this.save();
			return true;
		} else {
			const now = new Date();
			const diff = now.valueOf() - this.firstMessageStart.valueOf();
			const minutes = diff / (1000 * 60);
			if (minutes > RESTORE_TIME_MIN) {
				this.messageCount = 1;
				this.firstMessageStart = new Date();
				this.save();
				return true;
			}
			if (this.messageCount > MAX_MESSAGE) {
				return false;
			}
			return true;
		}
	}

	minutesLeft() {
		if (!this.firstMessageStart) {
			return 0;
		}
		const now = new Date();
		const diff = now.valueOf() - this.firstMessageStart.valueOf();
		const minutes = diff / (1000 * 60);
		return RESTORE_TIME_MIN - minutes;
	}

	setUserData(userData: UserData) {
		this.userData = userData;
		this.save();
	}

	removeUserData() {
		this.userData = null;
		this.save();
	}

	getUserData() {
		return this.userData;
	}
}

export const chatStorage = new ChatStorage();
