import {getB2BSiteUrl, getTHSiteUrl} from '../common/constants';

export const redirectToLogin = () => {
  const from = localStorage.getItem('from');
  if (from === 'th') {
    window.location.href = `${getTHSiteUrl()}/#logout`;
  } else {
    window.location.href = `${getB2BSiteUrl()}/#logout`;
  }
};

export const isDevB2B = () => window.location.href.includes('botify-ai');

export const isDevAdmin = () => window.location.href.includes('b2b-admin');

export const isProdB2B = () =>
  window.location.href.includes('exh.ai') &&
  !window.location.href.includes('admin');

export const isLocalEnv = () => window.location.href.includes('localhost');

export const isBothubEnv = () =>
  window.location.href.includes('localhost:3005') ||
  window.location.href.includes('exh-b2b-v2');

export const isMobileDevice = () => {
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  const isMobileWidht = window.innerWidth < 768;
  return isMobile || isMobileWidht;
};

export const convertBotName = (bot: string) => {
  return (
    bot
      .split(' ')
      .map((x) => x.toLowerCase())
      .join('_') + '_chat_id'
  );
};
