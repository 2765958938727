import {CheckCircle} from '@mui/icons-material';
import {InButton} from '../InButton/InButton';
import './SuccessPaymentPage.css';

type Props = {
  onClick: () => void;
  text: string;
};

export const SuccessPaymentPage = ({onClick, text}: Props) => (
  <div className="success-payment">
    <p className="success-paymnet__text">You have successfully subscribed!</p>
    <CheckCircle className="success-payment__check" />
    <InButton
      onClick={onClick}
      className="success-payment__button"
      id="success-payment-go-back"
    >
      {text}
    </InButton>
  </div>
);
