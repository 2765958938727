import './Footer.css';

type Props = {
  isLoggedIn: boolean;
};

export const Footer = ({isLoggedIn}: Props) => {
  const PP = isLoggedIn ? (
    <div className="footer__links">
      <a href="https://www.privacypolicies.com/live/35bcf464-abc3-4063-9242-7ef629330157">
        Privacy policy
      </a>
      <p>&</p>
      <a href="https://www.privacypolicies.com/live/0b4e6812-ea8f-4af5-bc02-2ce8d2e42095">
        Terms of service
      </a>
    </div>
  ) : (
    <div className="footer__links footer__links_margin">
      <a href="https://www.privacypolicies.com/live/35bcf464-abc3-4063-9242-7ef629330157">
        Privacy policy
      </a>
      <a href="https://www.privacypolicies.com/live/0b4e6812-ea8f-4af5-bc02-2ce8d2e42095">
        Terms of service
      </a>
      <a href="mailto:support@exh.ai">Contact us</a>
    </div>
  );
  return (
    <footer className="footer">
      <p className="footer__cr">© 2023 Ex-Human Inc.</p>
      {PP}
    </footer>
  );
};
