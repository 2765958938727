import {useDisplaySize} from '../../hooks/useDisplaySize';
import './CloseModalBtn.css';
import closeImg from '../../images/ico-close.svg';
import closeImgMobile from '../../images/ico-close-mobile.svg';

export const CloseModalBtn = ({
  onClose,
  skipInvertion = false,
}: {
  onClose: () => void;
  skipInvertion?: boolean;
}) => {
  const {width} = useDisplaySize();

  const src = skipInvertion
    ? closeImg
    : width > 740
    ? closeImg
    : closeImgMobile;
  return (
    <img src={src} alt="close" className="modal__close" onClick={onClose} />
  );
};
