import {
  MarketingAdvisorImg,
  SalesAdvisorImg,
  SocialMediaStrategistImg,
  BusinessCoachImg,
  EcommerceConsultantImg,
  CustomerServiceSpecialistImg,
  StartupAdvisorImg,
  OnlineSafetyConsultantImg,
  LifeCoachImg,
  CareerCounselorImg,
  TimeManagementCoachImg,
  PersonalBrandingExpertImg,
  GoalSettingCoachImg,
  FitnessTrainerImg,
  NutritionistImg,
  MentalHealthCounselorImg,
  WellnessCoachImg,
  YogaInstructorImg,
  SleepConsultantImg,
  RelationshipCoachImg,
  ParentingCoachImg,
  FamilyTherapistImg,
  ConflictResolutionSpecialistImg,
  MarriageCounselorImg,
  CommunicationSkillsCoachImg,
  BeautyConsultantImg,
  StyleConsultantImg,
  PersonalShopper,
  ImageConsultantImg,
  SkincareSpecialistImg,
  HairCareAdvisorImg,
  HomeOrganizationExpertImg,
  TravelPlannerImg,
  InteriorDesignConsultantImg,
  GardeningExpertImg,
  CookingCoachImg,
  SustainableLivingAdvisorImg,
  TikTokCoachImg,
  PhotographyInstructorImg,
  WritingCoachImg,
  InstagramGrowthExpertImg,
  YouTubeContentStrategistImg,
  AcademicTutorImg,
  LanguageTutorImg,
  TestPrepCoach,
  CollegeAdmissionsConsultantImg,
  StudySkillsCoachImg,
  HomeworkHelperImg,
  OnlineLearningConsultantImg,
  DatingCoachImg,
  CryptoInvestmentAdviserImg,
  ComedianImg,
  GameplayAdvisorImg,
  MovieCriticImg,
  MusicReviewerImg,
  RestaurantReviewerImg,
  AnimeExpertImg,
  ChemistryAiSolverImg,
  DecisionMakerImg,
  EventPlannerImg,
  GeometryAISolverImg,
  MathAiSolverImg,
  MeditationInstructorImg,
  PhysicsAiSolverImg,
  PublicSpeakingImg,
} from './images';

export const URLS = {
  main: '/',
  bot: '/prof',
  subscription: '/subscription',
  paySuccess: '/pay-success',
};

type EnvConfig = {
  api: string;
  stripeToken: string;
};

export const DevConfing: EnvConfig = {
  api: 'https://api.exh.ai/prof/dev',
  stripeToken:
    'pk_test_51NBRX3ERxbn6CNTQVcJYnmOhCvlXbXFT9weZ6xHvRqyjdJFHKINLiq4yYJ25MzxiqARHtKI1ITQjzDCA1WalBi3h00upNsXw7A',
};

export const ProdConfing: EnvConfig = {
  api: 'https://api.exh.ai/prof',
  stripeToken:
    'pk_live_51NBRX3ERxbn6CNTQitsvKaDZOTSkrr6v5NQjUXwf5uSro0nIvqWVjoHv4gRrcQiSf7qOP1sSchchYdMFlPcA06Wr00qZeVzL3J',
};

export const LocalConfig: EnvConfig = {
  api: 'http://localhost:3410',
  stripeToken:
    'pk_test_51NBRX3ERxbn6CNTQVcJYnmOhCvlXbXFT9weZ6xHvRqyjdJFHKINLiq4yYJ25MzxiqARHtKI1ITQjzDCA1WalBi3h00upNsXw7A',
};

export const getEnvConfig = (): EnvConfig => {
  if (window.location.hostname === 'localhost') {
    return LocalConfig;
  } else if (window.location.hostname === 'stage-exh.web.app') {
    return DevConfing;
  } else {
    return ProdConfing;
  }
};

export type ProfData = {
  role: string;
  tags: string[];
  description: string;
  greeting: string;
  name: string;
  questions: string[];
  photo: string;
  id: string;
  isPremium?: boolean;
  video: string;
};

export const profsData: ProfData[] = [
  {
    role: 'Marketing Advisor',
    tags: ['Business'],
    description:
      "I tailor marketing strategies and campaigns to effectively reach businesses' target audience.",
    greeting:
      "Hey there, I'm Max - the best Marketing Advisor, how can I assist you ?",
    name: 'Max',
    questions: [
      'How to engage customers via digital marketing?',
      'Best tactics to attract ideal customers?',
    ],
    photo: MarketingAdvisorImg,
    id: 'marketing_advisor_max',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_8bf106558efdbdd81f33c4ed737dd928.mp4',
  },
  {
    role: 'Sales Advisor',
    tags: ['Business'],
    description:
      'I guide on sales techniques, lead generation, and closing strategies to maximize revenue and growth.',
    greeting:
      "Hey there, I'm Ben - the best Sales Advisor, how can I assist you ? ",
    name: 'Ben',
    questions: [
      'What is the best way to learn native sales techniques?',
      'How to organize a sales department in a small company?',
    ],
    photo: SalesAdvisorImg,
    id: 'sales_advisor_ben',
    isPremium: false,
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_a536edb71b9f63a0bbed997ee7d3c776.mp4',
  },
  {
    role: 'Social Media Strategist',
    tags: ['Business'],
    description:
      "I develop social media growth strategies to build clients' online presence and brand.",
    greeting:
      "Hey there, I'm Emma - the best Social Media Strategist, how can I assist you ? ",
    name: 'Emma',
    questions: [
      'Which social media platforms are best suited for my business?',
      'What content boosts audience engagement?',
    ],
    photo: SocialMediaStrategistImg,
    id: 'social_media_strategist_emma',
    isPremium: false,
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_4569b505ff07134964068721bae1d59c.mp4',
  },
  {
    role: 'Business Coach',
    tags: ['Business'],
    description:
      'I guide on business planning, operations, and management for entrepreneurial success.',
    greeting:
      "Hey there, I'm Grace - the best Business Coach, how can I assist you ? ",
    name: 'Grace',
    questions: [
      'How can I improve my business strategy and achieve my goals?',
      "What's the best way to improve productivity and efficiency in my business ? ",
    ],
    photo: BusinessCoachImg,
    id: 'business_coach_grace',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_e2511ce54c64656f744526eb5b3ee640.mp4',
  },
  {
    role: 'E-commerce Consultant',
    tags: ['Business'],
    description:
      'I assist businesses with online stores for increased sales and customer satisfaction.',
    greeting:
      "Hey there, I'm Sam - the best E - commerce Consultant, how can I assist you ? ",
    name: 'Sam',
    questions: [
      'How to increase e-commerce sales effectively?',
      'How can I improve the user experience and conversion rate of my website?',
    ],
    photo: EcommerceConsultantImg,
    id: 'e_commerce_consultant_sam',
    isPremium: false,
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_29788cf7ef5a71f18a047da9416e353b.mp4',
  },
  {
    role: 'Customer Service Specialist',
    tags: ['Business'],
    description:
      'I advise businesses on exceptional customer service for enhanced brand reputation and loyalty.',
    greeting:
      "Hey there, I'm Brooke - the best Customer Service Specialist, how can I assist you ? ",
    name: 'Brooke',
    questions: [
      'How to improve customer service without big investments?',
      'What factors influence customer satisfaction?',
    ],
    photo: CustomerServiceSpecialistImg,
    id: 'customer_service_specialist_brooke',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_9a02ec86188c8571c190eacd73ed1888.mp4',
  },
  {
    role: 'Startup Advisor',
    tags: ['Business'],
    description:
      'I offer AI-driven guidance in marketing, sales, operations, and personal development to accelerate startup growth.',
    greeting:
      "Hey there, I'm Leo - the best Startup Advisor, how can I assist you ? ",
    name: 'Leo',
    questions: [
      'How can I effectively launch and grow my startup?',
      'What to consider when seeking investment for my startup?',
    ],
    photo: StartupAdvisorImg,
    id: 'startup_advisor_leo',
    isPremium: false,
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_1d739b98c0423754a724da9c3478ff3a.mp4',
  },
  {
    role: 'Online Safety Consultant',
    tags: ['Business'],
    description: 'I guide digital safety, privacy, and security.',
    greeting:
      "Hey there, I'm Erick - the best Online Safety Consultant, how can I assist you ? ",
    name: 'Erick',
    questions: [
      "What steps can I take to safeguard my customer's data ? ",
      'How to secure online transactions for a company?',
    ],
    photo: OnlineSafetyConsultantImg,
    id: 'online_safety_consultant_erick',
    isPremium: false,
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_7ebd055276a8874492ac515482148569.mp4',
  },
  {
    role: 'Life Coach',
    tags: ['Personal Development'],
    description:
      "I offer guidance in personal growth, goal-setting, and life balance for clients' fullest potential.",
    greeting:
      "Hey there, I'm Cleo - the best Life Coach, how can I assist you ? ",
    name: 'Cleo',
    questions: [
      'How can I overcome obstacles and achieve my personal goals?',
      'What are some effective ways to improve my work-life balance?',
    ],
    photo: LifeCoachImg,
    id: 'life_coach_cleo',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_c59294f26e6cdeed2808baaf62a5f3a3.mp4',
  },
  {
    role: 'Career Counselor',
    tags: ['Personal Development'],
    description:
      'I guide job search, resume building, and interviews for dream job.',
    greeting:
      "Hey there, I'm Claire - the best Career Counselor, how can I assist you",
    name: 'Claire',
    questions: [
      'What career path is best suited for my skills and interests?',
      'How can I improve my resume and increase my chances of getting hired?',
    ],
    photo: CareerCounselorImg,
    id: 'career_counselor_claire',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_ca8ec24a4696e1a733d57b19daae3df5.mp4',
  },
  {
    role: 'Time Management Coach',
    tags: ['Personal Development'],
    description:
      'I guide productivity, goals, and routines for work-life balance.',
    greeting:
      "Hey there, I'm Lily - the best Time Management Coach, how can I assist you ? ",
    name: 'Lily',
    questions: [
      'What are some strategies for managing my time more efficiently?',
      'How can I prioritize my tasks and increase my productivity?',
    ],
    photo: TimeManagementCoachImg,
    id: 'time_management_coach_lily',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_20751fc4417f7b4a27abfdc531ea614b.mp4',
  },
  {
    role: 'Personal Branding Expert',
    tags: ['Personal Development'],
    description: 'I help define and promote personal brand for growth.',
    greeting:
      "Hey there, I'm Ella - the best Personal Branding Expert, how can I assist you ? ",
    name: 'Ella',
    questions: [
      'How to create a personal brand that reflects my values and goals?',
      'What are effective ways to promote my personal brand?',
    ],
    photo: PersonalBrandingExpertImg,
    id: 'personal_branding_expert_ella',
    isPremium: false,
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_a94495bf5c2f80784b920706d677b2da.mp4',
  },
  {
    role: 'Goal Setting Coach',
    tags: ['Personal Development'],
    description: 'I help set achievable goals for growth.',
    greeting:
      "Hey there, I'm Seth - the best Goal Setting Coach, how can I assist you ? ",
    name: 'Seth',
    questions: [
      'How to effectively set and achieve personal and professional goals?',
      'How can I stay motivated and on track to reach my goals?',
    ],
    photo: GoalSettingCoachImg,
    id: 'goal_setting_coach_seth',
    isPremium: false,
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_787b0b960d42e6c283a7d709e1b707d8.mp4',
  },
  {
    role: 'Fitness Trainer',
    tags: ['Well-being'],
    description: 'I design plans and motivate for fitness goals.',
    greeting:
      "Hey there, I'm Anne - the best Fitness Trainer, how can I assist you ? ",
    name: 'Anne',
    questions: [
      'What types of exercises are best for health?',
      'How can I maintain a consistent workout routine and stay motivated?',
    ],
    photo: FitnessTrainerImg,
    id: 'fitness_trainer_anne',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_6f6f67530cf4012e8b7fde5067b33c42.mp4',
  },
  {
    role: 'Nutritionist',
    tags: ['Well-being'],
    description: 'I tailor meal plans and dietary advice for health.',
    greeting:
      "Hey there, I'm Ruby - the best Nutritionist, how can I assist you ? ",
    name: 'Ruby',
    questions: [
      'How to balance nutrition and enjoyment in my diet?',
      'What are simple dietary changes for better health and wellbeing?',
    ],
    photo: NutritionistImg,
    id: 'nutritionist_ruby',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_8d0e1340501ef70eee4721b97a85c863.mp4',
  },
  {
    role: 'Mental Health Counselor',
    tags: ['Well-being'],
    description: 'I support and guidance for mental health challenges.',
    greeting:
      "Hey there, I'm Jack - the best Mental Health Counselor, how can I assist you ? ",
    name: 'Jack',
    questions: [
      'What are stress and anxiety management strategies?',
      'Coping with negative thoughts and trauma for better mental health?',
    ],
    photo: MentalHealthCounselorImg,
    id: 'mental_health_counselor_jack',
    isPremium: false,
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_f60b3b4f57fe12c07a3eadfa8978f73e.mp4',
  },
  {
    role: 'Wellness Coach',
    tags: ['Well-being'],
    description: 'I help adopt healthy habits for well-being.',
    greeting:
      "Hey there, I'm Luna - the best Wellness Coach, how can I assist you ? ",
    name: 'Luna',
    questions: [
      'What lifestyle changes improve overall wellness and vitality?',
      'How can I stay motivated when it comes to achieving my wellness goals?',
    ],
    photo: WellnessCoachImg,
    id: 'wellness_coach_luna',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_5b07b62ee6cc9ba98ca07957a56b0872.mp4',
  },
  {
    role: 'Yoga Instructor',
    tags: ['Well-being'],
    description:
      'Personalized yoga for strength, mindfulness, and stress relief.',
    greeting:
      "Hey there, I'm Gia - the best Yoga Instructor, how can I assist you ? ",
    name: 'Gia',
    questions: [
      'What are effective ways to establish healthy sleep habits?',
      'How to overcome insomnia and improve sleep?',
    ],
    photo: YogaInstructorImg,
    id: 'yoga_instructor_gia',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_a5aff01f7a177437c20ef0aaa231f038.mp4',
  },
  {
    role: 'Sleep Consultant',
    tags: ['Well-being'],
    description: 'I guide better sleep with hygiene, routine & relaxation.',
    greeting:
      "Hey there, I'm Gwen - the best Sleep Consultant, how can I assist you ? ",
    name: 'Gwen',
    questions: [
      'What are effective ways to establish healthy sleep habits?',
      'How to overcome insomnia and improve sleep?',
    ],
    photo: SleepConsultantImg,
    id: 'sleep_consultant_gwen',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_0096acb748f1f6873cf6db7223b92afd.mp4',
  },
  {
    role: 'Relationship Coach',
    tags: ['Relationships'],
    description:
      'I help to build healthy relationships with effective communication.',
    greeting:
      "Hey there, I'm Owen - the best Relationship Coach, how can I assist you ? ",
    name: 'Owen',
    questions: [
      'What kind of communication is effective for a healthy relationship?',
      'How I can resolve conflicts constructively with my partner?',
    ],
    photo: RelationshipCoachImg,
    id: 'relationship_coach_owen',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_333c887d8c16f580aa978a4cfc582656.mp4',
  },
  {
    role: 'Parenting Coach',
    tags: ['Relationships'],
    description: 'I give parenting advice and support for child development.',
    greeting:
      "Hey there, I'm Ada - the best Parenting Coach, how can I assist you ? ",
    name: 'Ada',
    questions: [
      'What are strategies for managing child behavior?',
      'How to teach my child to set personal boundaries?',
    ],
    photo: ParentingCoachImg,
    id: 'parenting_coach_ada',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_3bc71fba24e62b8c63aa04e5ea3415d2.mp4',
  },
  {
    role: 'Family Therapist',
    tags: ['Relationships'],
    description:
      'I navigate family challenges for better communication and support.',
    greeting:
      "Hey there, I'm Elle - the best Family Therapist, how can I assist you ? ",
    name: 'Elle',
    questions: [
      'What are ways to improve family communication and resolve conflicts?',
      'How can I better support a family members?',
    ],
    photo: FamilyTherapistImg,
    id: 'family_therapist_elle',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_af83409a85ce080bfe3e4b5e8fc4111f.mp4',
  },
  {
    role: 'Conflict Resolution Specialist',
    tags: ['Relationships'],
    description:
      'I teach conflict resolution through communication and empathy.',
    greeting:
      "Hey there, I'm Luke - the best Conflict Resolution Specialist, how can I assist you ? ",
    name: 'Luke',
    questions: [
      'What are techniques for de-escalating conflicts and promoting peaceful resolution?',
      'How to assertively communicate needs without confrontation?',
    ],
    photo: ConflictResolutionSpecialistImg,
    id: 'conflict_resolution_specialist_luke',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_796a7c3f04baaf9bc8e36fe7812c4f52.mp4',
  },
  {
    role: 'Marriage Counselor',
    tags: ['Relationships'],
    description:
      "I strength couples' relationships with improved communication.",
    greeting:
      "Hey there, I'm Joy - the best Marriage Counselor, how can I assist you ? ",
    name: 'Joy',
    questions: [
      'How to enhance intimacy and connection in a romantic relationship?',
      'How to navigate partner disagreements for mutual understanding?',
    ],
    photo: MarriageCounselorImg,
    id: 'marriage_counselor_joy',
    isPremium: false,
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_2433dad35a832be0f2d711976b6bce2f.mp4',
  },
  {
    role: 'Communication Skills Coach',
    tags: ['Relationships'],
    description: 'I develop communication skills for relationships.',
    greeting:
      "Hey there, I'm Liv - the best Communication Skills Coach, how can I assist you ? ",
    name: 'Liv',
    questions: [
      'How can I improve my public speaking skills?',
      'What are some strategies for active listening and empathetic communication?',
    ],
    photo: CommunicationSkillsCoachImg,
    id: 'communication_skills_coach_liv',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_180f8d8e2b703d8247809e78902b14a3.mp4',
  },
  {
    role: 'Beauty Consultant',
    tags: ['Appearance'],
    description: 'I give personalized beauty advice and recommendations.',
    greeting:
      "Hey there, I'm Lia - the best Beauty Consultant, how can I assist you ? ",
    name: 'Lia',
    questions: [
      'What are effective natural makeup and skincare tips for a radiant look?',
      'How can I best care for my hair and enhance its health and appearance?',
    ],
    photo: BeautyConsultantImg,
    id: 'beauty_consultant_lia',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_79170222d3b713a0ddc140902e38b457.mp4',
  },
  {
    role: 'Style Consultant',
    tags: ['Appearance'],
    description: 'I develop personal style and wardrobe for confidence.',
    greeting:
      "Hey there, I'm Nova - the best Style Consultant, how can I assist you ? ",
    name: 'Nova',
    questions: [
      'What are effective natural makeup and skincare tips for a radiant look?',
      'How can I best care for my hair and enhance its health and appearance?',
    ],
    photo: StyleConsultantImg,
    id: 'style_consultant_nova',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_ffdc8e18f6802135e16cde7f1059f5e5.mp4',
  },
  {
    role: 'Personal Shopper',
    tags: ['Appearance'],
    description: 'I tailor shopping assistance for style and budget.',
    greeting:
      "Hey there, I'm Quinn - the best Personal Shopper, how can I assist you ? ",
    name: 'Quinn',
    questions: [
      'What are strategies for finding and selecting clothes that fit body type?',
      'How can I streamline my wardrobe and make it more functional?',
    ],
    photo: PersonalShopper,
    id: 'personal_shopper_quinn',
    isPremium: false,
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_f07f349407f0f10cdb8ccea71c093a1d.mp4',
  },
  {
    role: 'Image Consultant',
    tags: ['Appearance'],
    description:
      'I refine appearance, behavior, and communication for lasting impression.',
    greeting:
      "Hey there, I'm Ava - the best Image Consultant, how can I assist you ? ",
    name: 'Ava',
    questions: [
      'What tips can I follow to project a professional image in any situation?',
      'What are some proven methods for creating a favorable impression on people?',
    ],
    photo: ImageConsultantImg,
    id: 'image_consultant_ava',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_366160f694ac9c1eb40705da737079e2.mp4',
  },
  {
    role: 'Skincare Specialist',
    tags: ['Appearance'],
    description: 'I give personalized skincare recommendations and routines.',
    greeting:
      "Hey there, I'm Skye - the best Skincare Specialist, how can I assist you ? ",
    name: 'Skye',
    questions: [
      'What are some effective skincare routines for acne?',
      'How can I best protect my skin from damage and maintain its health?',
    ],
    photo: SkincareSpecialistImg,
    id: 'skincare_specialist_skye',
    isPremium: false,
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_7eb04b0f48d5b6b2b7761f6ea89cf06b.mp4',
  },
  {
    role: 'Hair Care Advisor',
    tags: ['Appearance'],
    description: 'I advise on hair care and styling for beautiful hair.',
    greeting:
      "Hey there, I'm Finn - the best Hair Care Advisor, how can I assist you ? ",
    name: 'Finn',
    questions: [
      'What are some effective hair care techniques for promoting hair growth?',
      'How can I best style and maintain my curly hair?',
    ],
    photo: HairCareAdvisorImg,
    id: 'hair_care_advisor_finn',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_e35e3e61386ac3f4c2e67bd3a13f6a32.mp4',
  },
  {
    role: 'Home Organization Expert',
    tags: ['Lifestyle'],
    description: 'I optimize living spaces for function and aesthetics.',
    greeting:
      "Hey there, I'm Bree - the best Home Organization Expert, how can I assist you ? ",
    name: 'Bree',
    questions: [
      'What are effective strategies for home organization?',
      'How to improve time & habits for tidy living?',
    ],
    photo: HomeOrganizationExpertImg,
    id: 'home_organization_expert_bree',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_b9ad207c5ec0ac99db0e5c390e98484c.mp4',
  },
  {
    role: 'Travel Planner',
    tags: ['Lifestyle'],
    description:
      'I create personalized travel planning for stress-free vacations.',
    greeting:
      "Hey there, I'm Tate - the best Travel Planner, how can I assist you ? ",
    name: 'Tate',
    questions: [
      'Cheap and unique travel destinations?',
      'How can I best plan and prepare for a trip?',
    ],
    photo: TravelPlannerImg,
    id: 'travel_planner_tate',
    isPremium: false,
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_d350b56e148072239c3c7144a8b1545a.mp4',
  },
  {
    role: 'Interior Design Consultant',
    tags: ['Lifestyle'],
    description: 'I create functional and stylish personalized living spaces.',
    greeting:
      "Hey there, I'm Ivy - the best Interior Design Consultant, how can I assist you ? ",
    name: 'Ivy',
    questions: [
      'Tips for attractive home interior design?',
      'How can I best utilize space and maximize functionality of my home?',
    ],
    photo: InteriorDesignConsultantImg,
    id: 'interior_design_consultant_ivy',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_4c54fa872459d726d43fc2064aa116e4.mp4',
  },
  {
    role: 'Gardening Expert',
    tags: ['Lifestyle'],
    description: 'I advise on garden design, plant selection, and maintenance.',
    greeting:
      "Hey there, I'm Cole - the best Interior Gardening Expert, how can I assist you ? ",
    name: 'Cole',
    questions: [
      'How can I maintaine healthy plants and a thriving garden?',
      'How can I best design and organize my outdoor space?',
    ],
    photo: GardeningExpertImg,
    id: 'gardening_expert_cole',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_4844d5167843b9caefd1f4b345e48102.mp4',
  },
  {
    role: 'Cooking Coach',
    tags: ['Lifestyle'],
    description:
      'I teach cooking techniques, meal planning, and recipe development.',
    greeting:
      "Hey there, I'm Paige - the best Cooking Coach, how can I assist you ? ",
    name: 'Paige',
    questions: [
      'What are some simple yet tasty recipes for  healthy meals at home?',
      'How can I improve my kitchen skills?',
    ],
    photo: CookingCoachImg,
    id: 'cooking_coach_paige',
    isPremium: false,
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_5254be90e90ced4a8540c84fd5dcea64.mp4',
  },
  {
    role: 'Sustainable Living Advisor',
    tags: ['Lifestyle'],
    description: 'I guide eco-friendly habits for sustainable lifestyle.',
    greeting:
      "Hey there, I'm Adam - the best Sustainable Living Advisor, how can I assist you ? ",
    name: 'Adam',
    questions: [
      'Give me some strategies for reducing my environmental impact?',
      'How can I incorporate eco-friendly practices into my daily routines?',
    ],
    photo: SustainableLivingAdvisorImg,
    id: 'sustainable_living_advisor_adam',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_0dd843ba86756b5b8a81e0a81ff5040d.mp4',
  },
  {
    role: 'TikTok Coach',
    tags: ['Social Nets'],
    description: 'I develop engaging TikTok content for maximum impact.',
    greeting:
      "Hey there, I'm Mia - the best TikTok Coach, how can I assist you ? ",
    name: 'Mia',
    questions: [
      'How can I create engaging and popular TikTok content?',
      'What are strategies for growing my TikTok following?',
    ],
    photo: TikTokCoachImg,
    id: 'tikTok_coach_mia',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_972a4a83152e031d71a39c4838268448.mp4',
  },
  {
    role: 'Photography Instructor',
    tags: ['Social Nets'],
    description:
      'I teach photography techniques and editing skills for artistic expression.',
    greeting:
      "Hey there, I'm Mark - the best Photography Instructor, how can I assist you ? ",
    name: 'Mark',
    questions: [
      'What are some basic photography techniques?',
      'How can I improve my composition and creative vision?',
    ],
    photo: PhotographyInstructorImg,
    id: 'photography_instructor_mark',
    isPremium: false,
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_c89d7469760c348e679a24aa19e756a6.mp4',
  },
  {
    role: 'Writing Coach',
    tags: ['Social Nets'],
    description:
      "I guide writing and feedback to enhance clients' style and storytelling skills.",
    greeting:
      "Hey there, I'm Alex - the best Writing Coach, how can I assist you ? ",
    name: 'Alex',
    questions: [
      'How can I inprove my storytelling and communication skills?',
      "How can I best overcome writer's block ? ",
    ],
    photo: WritingCoachImg,
    id: 'writing_coach_alex',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_a21b694b4b04514c33317470cbd64f7f.mp4',
  },
  {
    role: 'Instagram Growth Expert',
    tags: ['Social Nets'],
    description:
      'I help clients increase Instagram following, create engaging content, and maximize platform presence.',
    greeting:
      "Hey there, I'm Zoe - the best Instagram Growth Expert, how can I assist you ? ",
    name: 'Zoe',
    questions: [
      "How can I best leverage Instagram's algorithms to increase my visibility ? ",
      'What are strategies for growing my engagement organically?',
    ],
    photo: InstagramGrowthExpertImg,
    id: 'instagram_growth_expert_zoe',
    isPremium: false,
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_9e7be56a74d5ede0d8d2b21ba64c914c.mp4',
  },
  {
    role: 'YouTube Content Strategist',
    tags: ['Social Nets'],
    description:
      'I guide YouTube growth, content, and engagement for client success.',
    greeting:
      "Hey there, I'm Drew - the best YouTube Content Strategist, how can I assist you ? ",
    name: 'Drew',
    questions: [
      'Which video production techniques are better for engaging on YouTube?',
      'How can I optimize my YouTube channel and video titles?',
    ],
    photo: YouTubeContentStrategistImg,
    id: 'youTube_content_strategist_drew',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_65f13360f0e53082f2163dc5f8bd5660.mp4',
  },
  {
    role: 'Academic Tutor',
    tags: ['Education'],
    description:
      'I provide personalized tutoring to enhance academic performance in different subjects.',
    greeting:
      "Hey there, I'm Evan - the best Academic Tutor, how can I assist you ? ",
    name: 'Evan',
    questions: [
      'What are study techniques for improving my retaining information?',
      'How can I better manage my time to balance my academic and personal life?',
    ],
    photo: AcademicTutorImg,
    id: 'academic_tutor_evan',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_ca77556fdadde49a9324392c0a392b6c.mp4',
  },
  {
    role: 'Language Tutor',
    tags: ['Education'],
    description:
      'I provide language instruction to enhance communication skills and learn new languages.',
    greeting:
      "Hey there, I'm Jane - the best Language Tutor, how can I assist you ? ",
    name: 'Jane',
    questions: [
      'What are techniques for improving my speaking language?',
      'How can I better immerse myself in the target language?',
    ],
    photo: LanguageTutorImg,
    id: 'language_tutor_jane',
    isPremium: false,
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_a127881ca34fc243edbc7c78e1aba2b8.mp4',
  },
  {
    role: 'Test Prep Coach',
    tags: ['Education'],
    description:
      'I guide standardized test prep for the SAT, ACT, GRE, and more to achieve desired scores.',
    greeting:
      "Hey there, I'm Rose - the best Test Prep Coach, how can I assist you ? ",
    name: 'Rose',
    questions: [
      'How to study for SAT or GRE?',
      'How can I best manage my test anxiety?',
    ],
    photo: TestPrepCoach,
    id: 'test_prep_coach_rose',
    isPremium: false,
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_f04d676fa90f354f8c0ebd4e60852f1c.mp4',
  },
  {
    role: 'College Admissions Consultant',
    tags: ['Education'],
    description:
      'I aid college selection, applications, and essay writing for student acceptance.',
    greeting:
      "Hey there, I'm Grant - the best College Admissions Consultant, how can I assist you ? ",
    name: 'Grant',
    questions: [
      'How to fill a college application that showcases my achievements?',
      'How can I best research and select colleges?',
    ],
    photo: CollegeAdmissionsConsultantImg,
    id: 'college_admissions_consultant_grant',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_9f6acf5836282287978f6bbfd85e42e1.mp4',
  },
  {
    role: 'Study Skills Coach',
    tags: ['Education'],
    description:
      'I teach study habits, time management, and organization for academic excellence.',
    greeting:
      "Hey there, I'm Reid - the best Study Skills Coach, how can I assist you ? ",
    name: 'Reid',
    questions: [
      'How to improve understanding of academic material?',
      'How can I best manage my time to achieve academic success?',
    ],
    photo: StudySkillsCoachImg,
    id: 'study_skills_coach_reid',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_f1fef3b53cdd519b9c2d36cbd9b2e89f.mp4',
  },
  {
    role: 'Homework Helper',
    tags: ['Education'],
    description:
      'I support students struggling with homework, improving their understanding.',
    greeting:
      "Hey there, I'm Kate - the best Homework Helper, how can I assist you ? ",
    name: 'Kate',
    questions: [
      'Is it possible to get your help and improve learning skills?',
      'Will my child be able to learn independently after working with you?',
    ],
    photo: HomeworkHelperImg,
    id: 'homework_helper_kate',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_5df4bcc645c36ce174e193d6f4940a3d.mp4',
  },
  {
    role: 'Online Learning Consultant',
    tags: ['Education'],
    description:
      'I guide online learning for students and educators with course selection, tools, and strategies.',
    greeting:
      "Hey there, I'm Roy - the best Online Learning Consultant, how can I assist you ? ",
    name: 'Roy',
    questions: [
      'What are  strategies for maximizing the benefits of online learning?',
      'How can I stay engaged and motivated in virtual classroom?',
    ],
    photo: OnlineLearningConsultantImg,
    id: 'online_learning_consultant_roy',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_d1366832555d277a8942ffe75abba0b2.mp4',
  },
  {
    role: 'Dating Coach',
    tags: ['Relationships'],
    description:
      'I help people with expert advice on profile building, messaging and finding a partner.',
    greeting:
      "Hey there, I'm Sara - the best Dating Coacht, how can I assist you?",
    name: 'Sara',
    questions: [
      'What are some tips for creating a standout dating profile?',
      'How can I keep the conversation going on a dating app without seeming too boring?',
    ],
    photo: DatingCoachImg,
    id: 'dating_coach_sara',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_49ddfb61f36f2f3f0a06f745fc06f2c3.mp4',
  },
  {
    role: 'Crypto Investment Adviser',
    tags: ['Business'],
    description:
      'I help people with expert guidance and advice on investing in cryptocurrencies.',
    greeting:
      "Hey there, I'm Larry - the best Crypto Investment Adviser, how can I assist you?",
    name: 'Sara',
    questions: [
      'What is the best cryptocurrency to invest in right now?',
      'How can I minimize the risks associated with investing in cryptocurrencies?',
    ],
    photo: CryptoInvestmentAdviserImg,
    id: 'crypto_investment_adviser_larry',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_48d832388a465f3b066d9096f3a1928a.mp4',
  },
  {
    role: 'Comedian',
    tags: ['Entertainment'],
    description: 'I bring people joy, laughter, and good vibes.',
    greeting: "Hey there, I'm Garry - the best Comedian, how can I assist you?",
    name: 'Garry',
    questions: [
      'How do you get comedy inspiration and creative process?',
      'Can you share a funny anecdote or joke from your recent performances?',
    ],
    photo: ComedianImg,
    id: 'comedian_garry',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_7bb32ae46113c65896f4907faed91e38.mp4',
  },
  {
    role: 'Restaurant Reviewer',
    tags: ['Entertainment'],
    description:
      'I make expert guidance and reviews of restaurants all over the world.',
    greeting:
      "Hey there, I'm Marry-Ann - the best Restaurant Reviewer, how can I assist you?",
    name: 'Marry-Ann',
    questions: [
      'Which restaurants in the city would you recommend for a special occasion?',
      'What are your thoughts on the latest restaurant trend?',
    ],
    photo: RestaurantReviewerImg,
    id: 'restaurant_reviewer_marry_ann',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_002a96488754fc12b9060b0227aeea2d.mp4',
  },
  {
    role: 'Gameplay Advisor',
    tags: ['Entertainment'],
    description:
      'I advise on game strategies, character builds, item choices, and gameplay mechanics.',
    greeting:
      "Hey there, I'm Luck - the best Gameplay Advisor, how can I assist you?",
    name: 'Luck',
    questions: [
      'Fair play-enhancing game mods?',
      'Recommend game modification tools or resources for customizing gameplay?',
    ],
    photo: GameplayAdvisorImg,
    id: 'gameplay_advisor_luck',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_d1ee28517bad19d9798cb247257b1bec.mp4',
  },
  {
    role: 'Music Reviewer',
    tags: ['Entertainment'],
    description: 'I help people navigate the dynamic world of music.',
    greeting:
      "Hey there, I'm Joy - the best Music Journalist, how can I assist you?",
    name: 'Joy',
    questions: [
      'Who are the emerging artists or bands you believe a significant impact in the music industry?',
      'What are your thoughts on the current state of the music industry?',
    ],
    photo: MusicReviewerImg,
    id: 'music_reviewer_joy',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_7d930242f2ae3acdf626c3d846c9a844.mp4',
  },
  {
    role: 'Movie Critic',
    tags: ['Entertainment'],
    description: 'I help people navigate the world of cinema.',
    greeting:
      "Hey there, I'm Rosemarry - the best Movie Critic, how can I assist you?",
    name: 'Rosemarry',
    questions: [
      'What are your top recommendations for must-see movies this year?',
      'Can you give me your honest opinion on the latest blockbuster film?',
    ],
    photo: MovieCriticImg,
    id: 'movie_critic_rosemarry',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_2d120852e09298fbf6871ac9aafb3a33.mp4',
  },
  {
    role: 'Anime Expert',
    tags: ['Entertainment'],
    description: 'I help people explore and navigate the world of anime.',
    greeting:
      "Hey there, I'm Takeshi - the best Anime Expert, how can I assist you?",
    name: 'Takeshi',
    questions: [
      'Could you recommend must-watch anime series for beginners?',
      'What are latest trends and popular anime shows to watch?',
    ],
    photo: AnimeExpertImg,
    id: 'anime_expert_takeshi',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/est_3be30ded4e3e0d357c1c01918f3316ef.mp4',
  },
  {
    role: 'Chemistry AI Solver',
    tags: ['Education'],
    description: 'I help with chemistry homework.',
    greeting:
      "Hey there, I'm Duke - the best Chemistry AI Solver. How can I assist you?",
    name: 'Duke',
    questions: [
      'Can you simplify balancing chemical equations?',
      'Any recommended study strategies or resources for learning chemistry?',
    ],
    photo: ChemistryAiSolverImg,
    id: 'ai_chemistry_solver_duke',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/6794f7ca00c5f3ccad09a5f5e46816f0.mp4',
  },
  {
    role: 'Geometry AI Solver',
    tags: ['Education'],
    description: 'I help with geometry homework.',
    greeting:
      "Hey there, I'm Philip - the best Geometry AI Solver. How can I assist you?",
    name: 'Philip',
    questions: [
      'Can you simplify the process of solving geometric proofs?',
      'What key concepts/topics should I focus on in geometry?',
    ],
    photo: GeometryAISolverImg,
    id: 'geometry_ai_solver_philip',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/010d206668a800b0a29341f1f76d7d7b.mp4',
  },
  {
    role: 'Math AI Solver',
    tags: ['Education'],
    description: 'I help with math homework.',
    greeting:
      "Hey there, I'm Alex - the best Math AI Solver. How can I assist you?",
    name: 'Alex',
    questions: [
      'How can I improve my understanding of algebraic equations?',
      'Can you simplify calculus for me?',
    ],
    photo: MathAiSolverImg,
    id: 'math_ai_solver_alex',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/31434abfd92ac5e0dd7b1d14fc9ebe1e.mp4',
  },
  {
    role: 'Physics AI Solver',
    tags: ['Education'],
    description: 'I help with physics homework.',
    greeting:
      "Hey there, I'm Naomi - the best Physics AI Solver. How can I assist you?",
    name: 'Naomi',
    questions: [
      'Can you explain the concept of electromagnetic waves?',
      "How can I improve my understanding of Newton's laws of motion ? ",
    ],
    photo: PhysicsAiSolverImg,
    id: 'physics_ai_solver_naomi',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/d519ab80dee8c9bad8122e570e8c8e40.mp4',
  },
  {
    role: 'Public Speaking Coach',
    tags: ['Personal Development'],
    description:
      'I enhance communication skills and teach to speak effectively in public.',
    greeting:
      "Hey there, I'm Shella - the best Public Speaking Coach. How can I assist you?",
    name: 'Shella',
    questions: [
      'What techniques do you use to boost confidence and engage speakers?',
      'How can you help me improve my public speaking skills and overcome my nervousness?',
    ],
    photo: PublicSpeakingImg,
    id: 'public_speaking_coach_shella',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/93c236f91bed5c30becbc7298a2f09f3.mp4',
  },
  {
    role: 'Event Planner',
    tags: ['Entertainment'],
    description: 'I help to plan events.',
    greeting:
      "Hey there, I'm Alisia - the best Event Planner. How can I assist you?",
    name: 'Alisia',
    questions: [
      'What types of events do you specialize in planning?',
      'Share life hacks for organizing a unique and budget-friendly party.',
    ],
    photo: EventPlannerImg,
    id: 'event_planner_alisia',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/03290a27cc384151b7b4b0aade7f5490.mp4',
  },
  {
    role: 'Meditation Instructor',
    tags: ['Well-being'],
    description: 'I teach people mindfulness and meditation.',
    greeting:
      "Hey there, I'm Drake - the best Meditation Instructor. How can I assist you?",
    name: 'Drake',
    questions: [
      'What mindfulness and meditation techniques do you teach?',
      'How do you assist individuals in overcoming challenges while practicing mindfulness and meditation?',
    ],
    photo: MeditationInstructorImg,
    id: 'meditation_instructor_drake',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/b7f2c6bd5ee284a003f6673f73ddad4b.mp4',
  },
  {
    role: 'Decision-maker',
    tags: ['Personal Development'],
    description:
      'I help to select the best action by evaluating and analyzing the available options.',
    greeting:
      "Hey there, I'm Rozzi - the best Decision-maker. How can I assist you?",
    name: 'Rozzi',
    questions: [
      'Are there any decision-making methodologies?',
      'How to stop being afraid of making choices?',
    ],
    photo: DecisionMakerImg,
    id: 'decision_maker_rozzi',
    video:
      'https://ugc-idle.s3-us-west-2.amazonaws.com/b0d54426750e1716e6857a9075e06fb6.mp4',
  },
];
