import {useEffect, useRef, useState} from 'react';
import './Chat.css';

import {ChatSidebarRow} from './ChatSidebarRow';
import {ChatFooter} from '../../common-lib/src/components/ChatCommon/ChatFooter/ChatFooter';
import {ChatMain} from '../../common-lib/src/components/ChatCommon/ChatMain/ChatMain';
import {ChatContainer} from './ChatContainer';
import {useDisplaySize} from '../../common-lib/src/hooks/useDisplaySize';
import {useIsVisible} from '../../common-lib/src/hooks/useIsVisible';
import {VideoAvatar} from '../../common-lib/src/components/VideoAvatar/VideoAvatar';
import {addSlideInClass} from './utils';
import {ProfChatData, getByTag, chatTags, chatProfs} from './Chat.constants';
import {useChat} from '../BotPage/components/BotChat/useChat';
import {SmartReplies} from '../BotPage/components/BotChat/SmartReplies';
import {CategorySelect} from './CategorySelect/CategorySelect';
import {profAmplitudeApi} from '../../ampli/amplitudeApi';
import {useSearchParams} from 'react-router-dom';

type Props = {
  className?: string;
  withAddButton?: boolean;
  openLogin: () => void;
};

const findBotById = (id: string | null) =>
  chatProfs.find(prof => prof.id === id);

export const Chat = (props: Props) => {
  const [params, setParam] = useSearchParams();
  const botId = params.get('id');

  const [selectedTag, setSelectedTag] = useState<string>(
    findBotById(botId)?.tags[0] || chatTags[0]
  );
  const [chats, setChats] = useState<ProfChatData[]>(getByTag(selectedTag));
  const [selectedChat, setSelectedChat] = useState(
    findBotById(botId) || chats[0]
  );

  const {
    handleSmartReplyClick,
    focusInput,
    handleSendMessage,
    context,
    isLoading,
    inputValue,
    smartReplies,
    setInputValue,
  } = useChat(selectedChat, null, props.openLogin, true);

  const [isLimitReached, setIsLimitReached] = useState(false);
  const {isMobile, height} = useDisplaySize();
  const inputRef = useRef(null);
  const isInputVisible = useIsVisible(inputRef?.current);
  const chatRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isMobile && isInputVisible) {
      document.getElementById('chat-input')?.focus({
        preventScroll: true,
      });
    }

    if (isInputVisible) {
      addSlideInClass();
    }
  }, [isInputVisible]);

  const prevHeight = useRef(0);

  useEffect(() => {
    if (prevHeight.current - height > 140 && isMobile && isInputVisible) {
      focusInput();
    }
    prevHeight.current = height;
  }, [height]);

  useEffect(() => {
    if (chatRef?.current) {
      chatRef.current.scroll(0, 9999999999);
    }
  }, [context, smartReplies]);

  useEffect(() => {
    if (botId) {
      return;
    }
    setChats(getByTag(selectedTag));
  }, [selectedTag]);

  useEffect(() => {
    if (botId) {
      return;
    }
    if (selectedChat.name !== chats[0].name) {
      setSelectedChat(chats[0]);
    }
  }, [chats]);

  useEffect(() => {
    if (botId) {
      setParam({});
    }
  }, []);

  const handleChangeTag = (tag: string) => {
    profAmplitudeApi.categoryPress(
      tag.toLowerCase().replaceAll(' ', '_'),
      'chat'
    );
    setSelectedTag(tag);
  };

  const handleSelectChat = (chat: ProfChatData) => {
    profAmplitudeApi.chatVisit(
      chat.id,
      chat.tags[0].toLowerCase().replaceAll(' ', '_'),
      'main_page',
      'chat'
    );
    setSelectedChat(chat);
  };

  return (
    <ChatContainer className={`chat ${props.className || ''}`}>
      <div className="chat__sidebar">
        <CategorySelect
          tags={chatTags}
          selected={selectedTag}
          onChange={handleChangeTag}
          className="chat__category-select"
        />
        {chats.map((chatData, i) => {
          return (
            <ChatSidebarRow
              name={chatData.name}
              src={chatData.src}
              key={chatData.name + i}
              onClick={() => handleSelectChat(chatData)}
              selected={selectedChat.name === chatData.name}
            />
          );
        })}
      </div>
      <div className="chat__header">
        {selectedChat.name}
        <VideoAvatar
          src={selectedChat.video}
          defaultSrc={selectedChat.video}
          isDefault
          onEnd={() => {}}
          isInitial
          mobileLipsync
        />
      </div>
      <div className="chat__main-container" ref={chatRef}>
        <div className="chat__main">
          <ChatMain
            isLoading={isLoading}
            messages={context}
            person={selectedChat}
          />
          {!!smartReplies.length && (
            <SmartReplies
              messages={smartReplies}
              onClick={handleSmartReplyClick}
            />
          )}
        </div>
      </div>

      <div className="chat__footer" ref={inputRef}>
        <ChatFooter
          value={inputValue}
          onChange={setInputValue}
          onSubmit={handleSendMessage}
          disabled={isLoading || isLimitReached}
        />
      </div>
    </ChatContainer>
  );
};
