import {useState} from 'react';
import {profApi} from '../../profApi/ProfApi';
import {getBot} from '../../common/utils';

export const useFeedbackModal = (chat_id: string) => {
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);

  const closeFeedbackModal = () => {
    setIsFeedbackModalOpen(false);
  };

  const openFeedbackModal = () => {
    setIsFeedbackModalOpen(true);
  };

  const handleSubmitReport = (report: string) => {
    const bot = getBot(chat_id);
    profApi.feedback(bot.name || '', report);
    closeFeedbackModal();
  };

  return {
    isFeedbackModalOpen,
    handleSubmitReport,
    closeFeedbackModal,
    openFeedbackModal,
  };
};
