import './BotChatHeader.css';
import logoImg from './images/logo-new.svg';
import {MainContext} from '../../../App/App';
import {InButton} from '../../../../common-lib/src/components/InButton/InButton';
import {UserLogo} from './UserLogo/UserLogo';
import {useDisplaySize} from '../../../../common-lib/src/hooks/useDisplaySize';
import {useAuth0} from '@auth0/auth0-react';

type Props = {
  withBack?: boolean;
  className?: string;
};

export const MainHeader = ({className}: Props) => {
  const {width} = useDisplaySize();
  const {isLoading} = useAuth0();
  const isMobile = width <= 500;
  return (
    <MainContext.Consumer>
      {({userInfo, openLoginModal}) => {
        const isLoggedMobile = userInfo && isMobile;
        return (
          <div
            className={`bot-chat-header ${className || ''} ${
              isLoggedMobile ? 'main__header_logged-mobile' : ''
            }`}
          >
            <a href="/" className="bot-chat-header__logo-block">
              <img
                src={logoImg}
                alt="Ex-Human logo"
                className="bot-chat-header__logo"
              />
            </a>
            {!userInfo && (
              <div className="bot-chat-header__links bot-chat-header__links_main">
                <a className="bot-chat-header__link" href="/#how-it-works">
                  How it works
                </a>
                <a className="bot-chat-header__link" href="/#why-use">
                  Why use
                </a>
                {isMobile && !isLoading && (
                  <a
                    className="bot-chat-header__link"
                    href="/#login"
                    onClick={openLoginModal}
                  >
                    Log In
                  </a>
                )}
              </div>
            )}
            {!userInfo && !isMobile && !isLoading && (
              <InButton
                id="login"
                className="login-btn login-btn_main"
                onClick={() => openLoginModal()}
              >
                Log In
              </InButton>
            )}
            {userInfo && <UserLogo />}
          </div>
        );
      }}
    </MainContext.Consumer>
  );
};
