/* eslint-disable no-restricted-globals */
import './Main.css';
import {Header} from '../Header/Header';
import {MainContext} from '../App/App';
import {InButton} from '../../common-lib/src/components/InButton/InButton';
import {Footer} from '../Footer/Footer';
import {useEffect, useState} from 'react';
import {Tag} from '../Tag/Tag';
import {getBots} from '../../common/utils';
import {ProfInfo, ProfTile} from '../ProfTile/ProfTile';
import choiceImg from './images/choice.svg';
import requestImg from './images/request.svg';
import unionImg from './images/union.svg';
import dizzImg from './images/dizz.svg';
import aiImg from './images/ai.svg';
import {Card} from '../Card/Card';
import {BigCard} from '../BigCard/BigCard';
import {profAmplitudeApi} from '../../ampli/amplitudeApi';
import {chatStorage} from '../../chatStore/ChatStorage';
import starImg from './images/ico-star.svg';
import starWhiteImg from './images/ico-star-white.svg';
import arrowImg from './images/arrow.svg';
import {Chat} from '../Chat/Chat';

const tags = [
  'Featured',
  'Business',
  'Social Nets',
  'Education',
  'Personal Development',
  'Well-being',
  'Appearance',
  'Lifestyle',
  'Relationships',
  'Entertainment',
];

const cards = [
  {
    src: choiceImg,
    text: 'Pick the AI expert that suits your needs',
  },
  {
    src: requestImg,
    text: 'Explain your situation and drop a question',
  },
  {
    src: unionImg,
    text: 'Get an instant pro answer',
  },
];

const bigCards = [
  {
    src: dizzImg,
    text: 'Human specialist:',
    li: [
      'Expensive',
      'Limited availability',
      'The need to find your specialist',
      '"Human factor"',
    ],
    color: 'red',
  },
  {
    src: aiImg,
    text: 'AI-professional:',
    li: [
      '100x cheaper',
      '24/7 availabile',
      'No need to search info',
      'Private and safe',
    ],
    color: 'green',
  },
];

type Props = {
  onLogin?: () => void;
  loading: boolean;
};

export const Main = ({onLogin, loading}: Props) => {
  const [selectedTag, setSelectedTag] = useState<string>('Featured');
  const [selectedBots, setSelectedBots] = useState<ProfInfo[]>([]);

  const [leftArrowVisible, setLeftArrowVisible] = useState(false);
  const [rightArrowVisible, setRightArrowVisible] = useState(false);
  const [arrowsTop, setArrowsTop] = useState(0);
  const [rightArrowRight, setRightArrowLeft] = useState(0);

  useEffect(() => {
    function onResize() {
      if (rightArrowVisible) {
        const container = document.querySelector('.main .categories');
        const block = document.querySelector('.main .categories .block');
        if (!container) {
          return;
        }
        const left =
          (document.querySelector('.main')?.clientWidth || 0) - 80 - 10;
        const rightArrow = document.querySelector('.right-arrow');
        if (rightArrow) {
          setRightArrowLeft(left);
          //@ts-ignore
          setArrowsTop(block?.offsetTop + 0 || 0);
        }
      }
    }
    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [rightArrowVisible]);

  useEffect(() => {
    function onResize() {
      const container = document.querySelector('.main .categories .block');
      if (!container) {
        return;
      }
      if (container.scrollWidth > container.clientWidth) {
        setRightArrowVisible(true);
        const parent = document.querySelector('.main .categories .block');
        if (!parent) {
          return;
        }
        const callback = () => {
          if (parent.scrollLeft > 0) {
            setLeftArrowVisible(true);
          } else {
            setLeftArrowVisible(false);
          }
          if (parent.scrollLeft + parent.clientWidth < parent.scrollWidth - 5) {
            setRightArrowVisible(true);
          } else {
            setRightArrowVisible(false);
          }
        };
        parent.addEventListener('scroll', callback);
        return () => {
          parent.removeEventListener('scroll', callback);
        };
      }
    }
    onResize();

    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const handleArrowClick = (offset: number) => {
    const container = document.querySelector('.main .categories .block');
    if (!container) {
      return;
    }
    container.scrollLeft += offset;
  };

  const handleRightArrowClick = () => handleArrowClick(150);
  const handleLeftArrowClick = () => handleArrowClick(-150);

  const getLikedBots = () => {
    const liked = chatStorage.getLiked();
    const bots = getBots().filter(bot => liked.includes(bot.id));
    return bots;
  };

  useEffect(() => {
    localStorage.setItem('question', '');
    const liked = getLikedBots();
    if (liked.length) {
      setSelectedBots(liked);
      setSelectedTag('Favorite');
      return;
    } else {
      const bots = getBots()
        .sort(() => Math.random() - 0.5)
        .slice(0, 8);
      setSelectedBots(bots);
    }
    if (location?.hash) {
      const hash = location.hash.replace('#', '');
      document
        .getElementById(hash)
        ?.scrollIntoView({block: 'start', behavior: 'auto'});
    }
  }, []);

  useEffect(() => {
    if (selectedTag === 'Favorite') {
      setSelectedBots(getLikedBots());
      return;
    }
    if (selectedTag === 'Featured') {
      const bots = getBots().sort(() => Math.random() - 0.5);
      const {premium, free} = bots.reduce(
        (acc, bot) => {
          if (bot.isPremium) {
            acc.premium.push(bot);
          } else {
            acc.free.push(bot);
          }
          return acc;
        },
        {premium: [], free: []} as {premium: ProfInfo[]; free: ProfInfo[]}
      );
      const selected = [...premium.slice(0, 4), ...free.slice(0, 8)].sort(
        () => Math.random() - 0.5
      );
      setSelectedBots(selected);
      return;
    }
    const bots = getBots().filter(bot => bot.tags?.includes(selectedTag));
    setSelectedBots(bots);
  }, [selectedTag]);

  const handleTagClick = (tag: string) => {
    if (selectedTag === tag) {
      return;
    }
    profAmplitudeApi.categoryPress(tag.toLowerCase().replaceAll(' ', '_'));
    setSelectedTag(tag);
  };

  const handleButtonClick = (e: React.MouseEvent) => {
    const source =
      e.currentTarget.textContent === 'Try now' ? 'how_it_works' : 'why_use';
    profAmplitudeApi.goToCategoriesPresed(source);

    const categoriesBlock = document.getElementsByClassName('.categories')?.[0];
    if (categoriesBlock) {
      categoriesBlock.scrollIntoView({block: 'start', behavior: 'smooth'});
    } else {
      document
        .getElementsByClassName('main__title')?.[0]
        ?.scrollIntoView({block: 'start', behavior: 'smooth'});
    }
  };

  return (
    <MainContext.Consumer>
      {({userInfo, loginAndSubscribe}) => {
        return (
          <>
            <main className="main">
              <Header />
              {!userInfo && (
                <>
                  <h1 className="main__title">AI Professionals</h1>
                  <p className="main__subtitle">
                    Get help from our AI pros and crush your goals 10 times
                    faster on any language
                  </p>
                </>
              )}

              {!userInfo && !loading && (
                <div className="main__chat">
                  <Chat openLogin={loginAndSubscribe} />
                </div>
              )}

              {userInfo && (
                <div className="categories" id="categories">
                  <h3 className="title">
                    Pick a category to find experts who can assist you:
                  </h3>
                  <div className="block">
                    {leftArrowVisible && (
                      <div onClick={handleLeftArrowClick}>
                        <div
                          className="left-arrow"
                          style={{
                            top: `${arrowsTop}px`,
                          }}
                        ></div>
                        <img
                          src={arrowImg}
                          alt=""
                          className="arrow-img left-arrow-img"
                          style={{
                            top: `${arrowsTop + 30}px`,
                            left: '0',
                          }}
                        />
                      </div>
                    )}
                    {tags.map(tag => {
                      let icon;
                      if (tag === 'Featured' && getLikedBots().length) {
                        tag = 'Favorite';
                        icon = selectedTag === tag ? starWhiteImg : starImg;
                      }
                      return (
                        <Tag
                          onClick={() => handleTagClick(tag)}
                          tag={tag}
                          key={tag}
                          isSelected={selectedTag === tag}
                          icon={icon}
                        />
                      );
                    })}
                    {rightArrowVisible && (
                      <div onClick={handleRightArrowClick}>
                        <div
                          className="right-arrow"
                          style={{
                            left: `${rightArrowRight}px`,
                            top: `${arrowsTop}px`,
                          }}
                        ></div>
                        <img
                          src={arrowImg}
                          alt=""
                          className="arrow-img right-arrow-img"
                          style={{
                            left: `${rightArrowRight + 50}px`,
                            top: `${arrowsTop + 30}px`,
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
              {userInfo && (
                <div className="profs">
                  {selectedBots.map(bot => {
                    return <ProfTile bot={bot} key={bot.id} />;
                  })}
                </div>
              )}
              {!userInfo && (
                <>
                  <h2 className="how" id="how-it-works">
                    How it works
                  </h2>
                  <p className="how__subtitle">
                    Simpler than scrolling on the internet
                  </p>
                  <div className="cards">
                    {cards.map((card, index) => {
                      return (
                        <Card
                          key={index}
                          number={index + 1}
                          src={card.src}
                          text={card.text}
                        />
                      );
                    })}
                  </div>
                  <InButton
                    id="try"
                    isFilled
                    className="try-btn"
                    onClick={handleButtonClick}
                  >
                    Try now
                  </InButton>
                </>
              )}
              {!userInfo && (
                <>
                  <h2 className="why" id="why-use">
                    Why use AI professionals
                  </h2>
                  <div className="big-cards">
                    {bigCards.map((bigCard, index) => {
                      return (
                        <BigCard
                          key={index}
                          src={bigCard.src}
                          text={bigCard.text}
                          li={bigCard.li}
                          color={bigCard.color}
                        />
                      );
                    })}
                  </div>
                  <h3 className="chat-title">
                    Chat with AI expert for pro advice 24/7
                  </h3>
                  <InButton
                    id="chat"
                    isFilled
                    className="chat-btn"
                    onClick={handleButtonClick}
                  >
                    Start chat
                  </InButton>
                </>
              )}

              <Footer isLoggedIn={!!userInfo} />
            </main>
          </>
        );
      }}
    </MainContext.Consumer>
  );
};
