import {Modal} from '@mui/material';
import {InButton} from '../../common-lib/src/components/InButton/InButton';
import './LimitReachedModal.css';
import {CloseModalBtn} from '../../common-lib/src/components/CloseModalBtn/CloseModalBtn';
import {CookieHandler} from '../../profApi/CookieHandler';
import {MAX_MESSAGES_PER_USER} from '../../profApi/ProfApi';
import {useEffect} from 'react';

type Props = {
  openned: boolean;
  onClose: () => void;
  onSuccess: (isLocked: boolean) => void;
};

export const LimitReachedModal = ({openned, onClose, onSuccess}: Props) => {
  const isLocked =
    CookieHandler.getMessagesCounter() < MAX_MESSAGES_PER_USER ||
    !!localStorage.getItem('unlock');

  const firstRow = isLocked ? 'Subscribe' : 'Your free';
  const secondRow = isLocked ? 'to unlock premium AI Pros' : 'messages are up';

  useEffect(() => {
    localStorage.removeItem('unlock');
  }, [openned]);
  return (
    <Modal className="limit-reached-modal" open={openned} onClose={onClose}>
      <div className="column limit-reached-modal__inner">
        <h2 className="limit-reached__title">
          {firstRow}
          <br />
          {secondRow}
        </h2>
        <p className="limit-reached__text">
          {isLocked ? '' : 'Subscribe to keep chatting with our AI Pros'}
        </p>
        <div className="btns">
          <InButton
            className="btns__btn btns__cancel"
            isFilled
            id="cancel"
            onClick={onClose}
          >
            Close
          </InButton>
          <InButton
            className="btns__btn btns__limit-reached"
            isFilled
            id="limit-reached"
            onClick={() => onSuccess(isLocked)}
          >
            Register now
          </InButton>
        </div>
        <CloseModalBtn onClose={onClose} />
      </div>
    </Modal>
  );
};
