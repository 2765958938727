import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import './BotPage.css';
import {BotChatComponent} from './components/BotChat/BotChat';
import {BotInfoComponent} from './components/BotInfo/BotInfo';
import {getBot, getBots} from '../../common/utils';
import {Spinner} from '../../common-lib/src/components/Spinner/Spinner';
import {chatStorage} from '../../chatStore/ChatStorage';
import {useDisplaySize} from '../../common-lib/src/hooks/useDisplaySize';
import {MobileBotPage} from './components/MobileBotPage/MobileBotPage';
import {OtherBotTile} from '../OtherBotTile/OtherBotTile';
import {BotChatHeader} from './components/BotChatHeader/BotChatHeader';
import {useChat} from './components/BotChat/useChat';
import {ProfInfo} from '../ProfTile/ProfTile';
import {MainContext} from '../App/App';
import {UserData} from '../App/App';

export const BotPage = () => {
  return (
    <MainContext.Consumer>
      {({userInfo, loginAndSubscribe}) => {
        return (
          <BotPageInner
            userInfo={userInfo}
            loginAndSubscribe={loginAndSubscribe}
          />
        );
      }}
    </MainContext.Consumer>
  );
};

type Props = {
  userInfo: UserData | null;
  loginAndSubscribe: () => void;
};

const BotPageInner = ({userInfo, loginAndSubscribe}: Props) => {
  const params = useParams();
  const botId = params.botId;
  const [isLoading, setIsLoading] = useState(true);
  const [bot, setBot] = useState<ProfInfo | null>(null);
  const [otherBots, setOtherBots] = useState<ProfInfo[]>([]);
  const [categoryBots, setCategoryBots] = useState<ProfInfo[]>([]);
  const [visitedBots, setVisitedBots] = useState<ProfInfo[]>([]);
  const {width} = useDisplaySize();
  const isMobile = width < 800;
  const useChatValue = useChat(bot, userInfo, loginAndSubscribe);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      if (!botId) {
        window.location.href = '/';
        return null;
      }
      const bot = getBot(botId);
      if (!bot || (bot.isPremium && !userInfo?.paid)) {
        window.location.href = '/';
        return null;
      }

      chatStorage.setChatVisited(botId);

      setBot(bot);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!bot) {
      return;
    }
    const added = new Set();
    const bots = getBots().filter(x => x.id !== bot.id);

    const visitedIds = chatStorage.getChatVisited();
    if (visitedIds) {
      const visited = visitedIds
        .map(id => bots.find(x => x.id === id))
        .filter(x => x !== undefined)
        .slice(0, 3) as ProfInfo[];
      setVisitedBots(visited);
      visited.forEach(x => added.add(x.id));
    } else {
      setVisitedBots([]);
    }
    const sameCategory = bots
      .filter(x => !added.has(x.id))
      .filter(x => x.tags.includes(bot.tags[0]))
      .sort(() => (Math.random() > 0.5 ? 1 : -1))
      .slice(0, 3);
    setCategoryBots(sameCategory);
    sameCategory.forEach(x => added.add(x.id));

    const other = bots
      .filter(x => !added.has(x.id))
      .sort(() => (Math.random() > 0.5 ? 1 : -1))
      .slice(0, 3);
    setOtherBots(other);
  }, [bot]);

  useEffect(() => {
    fetchData();
  }, [botId]);

  if (isMobile && bot !== null) {
    return (
      <MobileBotPage
        bot={bot}
        isLoading={isLoading}
        useChatValue={useChatValue}
      />
    );
  }

  return (
    <div className="bot-page-container">
      {!isMobile && <BotChatHeader withBack />}
      <div className={`bot-page ${isLoading ? 'bot-page_height' : ''}`}>
        {isLoading && <Spinner withLayout={false} />}
        {!isLoading && bot && (
          <div className="bot-page-content">
            <div className="bot-info bot-info_other">
              <div className="bot-info__others-block">
                <div className="bot-info__header-text">
                  {bot.tags[0]} Professionals:
                </div>
                <div className="bot-info__other-bots">
                  {categoryBots.map((bot, index) => (
                    <OtherBotTile
                      bot={bot}
                      key={bot.id + index}
                      paid={userInfo?.paid}
                      onUnlockClick={loginAndSubscribe}
                    />
                  ))}
                </div>
              </div>

              <div className="bot-info__others-block">
                <div className="bot-info__header-text">
                  Other Professionals:
                </div>
                <div className="bot-info__other-bots">
                  {otherBots.map((bot, index) => (
                    <OtherBotTile
                      bot={bot}
                      key={bot.id + index}
                      paid={userInfo?.paid}
                      onUnlockClick={loginAndSubscribe}
                    />
                  ))}
                </div>
              </div>

              {!!visitedBots.length && (
                <div className="bot-info__others-block">
                  <div className="bot-info__header-text">Recently used:</div>
                  <div className="bot-info__other-bots">
                    {visitedBots.map((bot, index) => (
                      <OtherBotTile
                        bot={bot}
                        key={bot.id + index}
                        paid={userInfo?.paid}
                        onUnlockClick={loginAndSubscribe}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
            <BotChatComponent bot={bot} useChatValue={useChatValue} />
            <BotInfoComponent bot={bot} />
          </div>
        )}
      </div>
    </div>
  );
};
