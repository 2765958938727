import './BotInfo.css';
import bubble from '../../images/bubble.svg';
import {getMessagesQty} from '../../../../common-lib/src/components/BotTile/MessagesQty.utils';
import {BotInfo} from '../../../../common-lib/src/components/BotTile/BotTile';
import {useState} from 'react';
import {LikeReport} from '../LikeReport/LikeReport';
import {chatStorage} from '../../../../chatStore/ChatStorage';
import {FeedbackModal} from '../../../FeedbackModal/FeedbackModal';
import {useFeedbackModal} from '../../../FeedbackModal/useFeedbackModal';

type BotInfoProps = {
  bot: BotInfo;
};

export const BotInfoComponent = ({bot}: BotInfoProps) => {
  const {name, description, messagesQty, id} = bot;
  const [isLiked, setIsLiked] = useState(chatStorage.isLiked(bot.id));
  const messagesQtyStr = getMessagesQty(messagesQty);
  const {
    isFeedbackModalOpen,
    handleSubmitReport,
    closeFeedbackModal,
    openFeedbackModal,
  } = useFeedbackModal(id);

  const handleLike = () => {
    setIsLiked(!isLiked);
    if (isLiked) {
      chatStorage.unlike(bot.id);
    } else {
      chatStorage.like(bot.id);
    }
  };

  return (
    <div className="bot-info">
      <p className="bot-info__header-text">Professional Info:</p>
      <img src={bot.src} alt="avatar" className="bot-info__avatar" />
      <h2 className="bot-info__name">{name}</h2>
      <div className="bot-info__messages-qty row">
        <img src={bubble} alt="bubble" className="bot-info__bubble" />
        <p className="bot-info__messages-qty-text">
          {messagesQtyStr} people helped
        </p>
      </div>
      <p className="bot-info__description">{description}</p>
      <LikeReport
        botId={bot.id}
        onLike={handleLike}
        onReport={openFeedbackModal}
        isLiked={isLiked}
      />
      <FeedbackModal
        onSubmit={handleSubmitReport}
        openned={isFeedbackModalOpen}
        onClose={closeFeedbackModal}
      />
    </div>
  );
};
