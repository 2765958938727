export const removeC = () => {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  }
};

export const convertToId = (str: string) =>
  str.replaceAll(' ', '-').toLowerCase();

const convertBase64ToType = (source: string | undefined, type: 'image' | 'video') => {
  if (!source) {
    return null;
  }
  const byteString = atob(source);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([int8Array], { type: type === 'image' ? 'image/png' : 'video/mp4' });
  return URL.createObjectURL(blob);
}

export const convertBase64ToVideo = ({ base64_video }: { base64_video: string }) => {
  return convertBase64ToType(base64_video, 'video');
};

export const convertBase64ToImage = ({ base64_image }: { base64_image: string }) => {
  return convertBase64ToType(base64_image, 'image');
};