import './UserLogo.css';
import userLogo from '../images/userlogo.svg';
import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import coinImg from '../images/coin.svg';
import supportImg from '../images/support.svg';
import coinBoldImg from '../images/coin-bold.svg';
import homeImg from '../images/home.svg';
import homeBoldImg from '../images/home-bold.svg';
import logoutImg from '../images/logout.svg';
import {MainContext} from '../../../../App/App';
import {URLS} from '../../../../../common/constants';
import {CookieHandler} from '../../../../../profApi/CookieHandler';
import {profApi} from '../../../../../profApi/ProfApi';
import {chatStorage} from '../../../../../chatStore/ChatStorage';
import {useAuth0} from '@auth0/auth0-react';

type TooltipOptionProps = {
  text: string;
  link: string;
  icon: string;
  selected?: boolean;
};

const TooltipOption = ({text, link, icon, selected}: TooltipOptionProps) => {
  return (
    <Link to={link} className="tooltip-option">
      <img src={icon} alt="text" className="tooltip-option_img" />
      <p
        className={`tooltip-option__text ${
          selected ? 'tooltip-option__text_bold' : ''
        }`}
      >
        {text}
      </p>
    </Link>
  );
};

export const UserLogo = () => {
  const [opened, setOpened] = useState(false);
  const {logout} = useAuth0();

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      const className = event.target.classList?.[0];
      if (
        className !== 'user-logo__image' &&
        className !== 'user-logo__tooltip' &&
        className !== 'tooltip-option' &&
        className !== 'tooltip-option_img' &&
        className !== 'tooltip-option__text'
      ) {
        setOpened(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleOpened = () => setOpened(!opened);
  const isMain = window.location.pathname === URLS.main;
  const isSubscription = window.location.pathname === URLS.subscription;
  return (
    <MainContext.Consumer>
      {({userInfo, setUserInfo}) => {
        const handleLogout = () => {
          setUserInfo(null);
          CookieHandler.removeTokenCookie();
          profApi.resetToken();
          chatStorage.removeUserData();
          logout({logoutParams: {returnTo: window.location.origin}});
        };
        return (
          <div className="user-logo-container">
            <img
              src={userLogo}
              className="user-logo__image"
              alt="user logo"
              onClick={toggleOpened}
            />
            {opened && (
              <div className="user-logo__tooltip">
                <div className="signed-as">Signed in as {userInfo?.email}</div>
                <TooltipOption
                  text="Home"
                  link={URLS.main}
                  icon={isMain ? homeBoldImg : homeImg}
                  selected={isMain}
                />
                <TooltipOption
                  text="Subscription"
                  link={URLS.subscription}
                  icon={isSubscription ? coinBoldImg : coinImg}
                  selected={window.location.pathname === URLS.subscription}
                />
                <a href="mailto:support@exh.ai" className="tooltip-option">
                  <img
                    src={supportImg}
                    alt="contact us"
                    className="tooltip-option_img"
                  />
                  <p className="tooltip-option__text">Support</p>
                </a>
                <div className="tooltip-option" onClick={handleLogout}>
                  <img
                    src={logoutImg}
                    alt="text"
                    className="tooltip-option_img"
                  />
                  <p className="tooltip-option__text">Log Out</p>
                </div>
              </div>
            )}
          </div>
        );
      }}
    </MainContext.Consumer>
  );
};
