import './TextArea.css';
import cn from 'classnames';

type Props = {
  onChange: (value: string) => void;
  value: string;
  className?: string;
  placeholder?: string;
  maxLength?: number;
  showError?: boolean;
  disabled?: boolean;
  disabledMessage?: string;
};

export const TextArea = ({
  showError,
  className = '',
  placeholder = '',
  maxLength = 140,
  value,
  onChange,
  disabled = false,
  disabledMessage = '',
}: Props) => {
  return (
    <div className="text-area__container">
      <textarea
        wrap="soft"
        onChange={e => onChange(e.target.value)}
        className={cn(`text-area ${className}`, {
          'text-area_invalid': showError,
          'text-area_disabled': disabled,
        })}
        value={value}
        placeholder={disabled ? '' : placeholder}
        maxLength={maxLength}
        disabled={disabled}
      />
      {disabled && disabledMessage && (
        <div className="text-area__disabled-layer">{disabledMessage}</div>
      )}
    </div>
  );
};
