import {useNavigate} from 'react-router-dom';
import {InButton} from '../../common-lib/src/components/InButton/InButton';
import {MainContext, MainContextType} from '../App/App';
import {Header} from '../Header/Header';
import './SubscriptionPage.css';
import linkImg from './images/link.svg';
import {useEffect, useState} from 'react';
import {URLS} from '../../common/constants';
import {MAX_MESSAGES_PER_USER, profApi} from '../../profApi/ProfApi';
import {enqueueSnackbar} from 'notistack';
import {PaymentPlan, UserPaymentInfo} from '../../profApi/ProfApi.types';
import {Spinner} from '../../common-lib/src/components/Spinner/Spinner';
import {CookieHandler} from '../../profApi/CookieHandler';
import {UnsubscribeModal} from '../UnsubscribeModal/UnsubscribeModal';
import {profAmplitudeApi} from '../../ampli/amplitudeApi';
import {DoubleSwitcher} from '../../common-lib/src/components/DoubleSwitcher/DoubleSwitcher';

type SubscribeButtonProps = {
  onSubscribe: (plan: PaymentPlan) => void;
  onUnsubscribe: () => void;
  plan: PaymentPlan;
  paid: boolean;
  canceled: boolean;
};

const SubscribeButton = ({
  onSubscribe,
  onUnsubscribe,
  plan,
  paid,
  canceled,
}: SubscribeButtonProps) => {
  const getText = () => {
    if (!paid) {
      return 'Subscribe';
    }
    return canceled ? 'Resume Subscription' : 'Cancel Subscription';
  };

  return (
    <InButton
      isFilled
      id={plan}
      className={`plan__btn ${paid && !canceled ? 'plan__btn_active' : ''}`}
      onClick={paid && !canceled ? onUnsubscribe : () => onSubscribe(plan)}
    >
      {getText()}
    </InButton>
  );
};

const SubscriptionPageInner = ({
  userInfo,
  isUserInfoLoading,
  loading,
}: Pick<MainContextType, 'userInfo' | 'isUserInfoLoading'> & {
  loading: boolean;
}) => {
  const [isLoading, setIsLoading] = useState(loading);
  const [paymentInfo, setPaymentInfo] = useState<UserPaymentInfo | null>(null);
  const navigate = useNavigate();
  const [unsubscribeModalOpened, setUnsubscribeModalOpened] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState<PaymentPlan>('Yearly');

  const fetchPaymentInfo = async () => {
    setIsLoading(true);
    try {
      const {paymentInfo} = await profApi.getSubscriptionInfo();
      setPaymentInfo(paymentInfo);
      if (paymentInfo?.billingPeriod) {
        setSelectedPeriod(paymentInfo.billingPeriod);
      }
    } catch (e) {
      console.error(e);
      enqueueSnackbar('Error while fetching payment info', {
        variant: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isUserInfoLoading) {
      return;
    }

    localStorage.removeItem('afterLogin');
    if (!userInfo) {
      navigate(URLS.main);
    }
    fetchPaymentInfo();
  }, [isUserInfoLoading]);

  const handleSubscribeClick = async (
    plan: UserPaymentInfo['billingPeriod']
  ) => {
    try {
      if (!plan) {
        return;
      }
      profAmplitudeApi.subscribePressed(plan);
      const {url} = await profApi.subscriptionCheckout(plan);
      if (!url || url === URLS.subscription) {
        fetchPaymentInfo();
      } else {
        window.location.href = url;
      }
    } catch (e) {
      enqueueSnackbar('Error while subscribing', {
        variant: 'error',
      });
      console.error(e);
    }
  };

  const handleUnsubscribeClick = () => {
    setUnsubscribeModalOpened(true);
  };

  const handleConfirmUnsubscribe = async () => {
    setUnsubscribeModalOpened(false);
    await profApi.subscriptionCancel();
    await fetchPaymentInfo();
    setIsLoading(false);
  };

  const price = paymentInfo?.sum ? `$${paymentInfo?.sum}` : 'Free';
  const billingPeriod = paymentInfo?.billingPeriod
    ? paymentInfo?.billingPeriod
    : '-';
  const renewalDate = paymentInfo?.renewalDate ? paymentInfo?.renewalDate : '-';
  const messagesQty = CookieHandler.getMessagesCounter();
  const isLimitExceeded =
    !paymentInfo?.billingPeriod && messagesQty >= MAX_MESSAGES_PER_USER;
  const messagesUsedStrSign = paymentInfo?.billingPeriod
    ? '∞'
    : MAX_MESSAGES_PER_USER;
  const messagesUsedStr = paymentInfo?.billingPeriod
    ? `${paymentInfo.messagesUsed}`
    : isLimitExceeded
    ? `${MAX_MESSAGES_PER_USER}`
    : `${messagesQty}`;
  const isMonthly = paymentInfo?.billingPeriod === 'Monthly';
  const isYearly = paymentInfo?.billingPeriod === 'Yearly';
  const billingUrl = paymentInfo?.invoiceUrl || '#';

  const getTextOnLoad = (text: string | number) => {
    if (isLoading) {
      return '-';
    }
    return text;
  };

  return (
    <main className="subsciption">
      {isLoading && <Spinner withLayout />}
      <Header />

      <div className="subscription__container">
        <div className="subsciption__header">
          <h2 className="subscription__title">Subscription</h2>
          {paymentInfo?.invoiceUrl && (
            <a
              href={billingUrl}
              className="subscription__billing-link"
              target="blank"
            >
              Billing & Invoice Details
              <img
                src={linkImg}
                alt="link"
                className="subscription__billing-link-img"
              />
            </a>
          )}
        </div>
        <div
          className={`subscription__info-block ${
            userInfo?.paid ? '' : 'subscription__info-block_short'
          }`}
        >
          <div className="info-card">
            <h4 className="info-card__title">Price</h4>
            <p className="info-card__text">{getTextOnLoad(price)}</p>
          </div>
          {userInfo?.paid && (
            <div className="info-card">
              <h4 className="info-card__title">Billing period</h4>
              <p className="info-card__text">{getTextOnLoad(billingPeriod)}</p>
            </div>
          )}
          {userInfo?.paid && (
            <div className="info-card">
              <h4 className="info-card__title">
                {paymentInfo?.isCanceled ? 'End date' : 'Renewal date'}
              </h4>
              <p className="info-card__text">{getTextOnLoad(renewalDate)}</p>
            </div>
          )}
          <div className="info-card">
            <h4 className="info-card__title">Messages used</h4>
            <div
              className={`row info-card__text ${
                isLimitExceeded ? 'info-card__text_red' : ''
              }`}
            >
              <p>{getTextOnLoad(messagesUsedStr)}/</p>
              <p style={paymentInfo?.billingPeriod ? {fontSize: 24} : {}}>
                {getTextOnLoad(messagesUsedStrSign)}
              </p>
            </div>
          </div>
        </div>
        <div className="subscription__plans-container">
          <h3 className="subscription__plans-title">Premium Plans</h3>
          <div className="subscription__plans">
            <div className="subscription__plan">
              <h4 className="plan__title">Free</h4>
              <p className="plan__subtitle">
                Best for individuals who want to try the technology
              </p>
              <ul className="plan__list">
                <li className="plan__list-item">
                  <p>•</p>
                  <p className="plan__list-text">
                    get 5 messages from AI Professionals
                  </p>
                </li>
              </ul>
              <p className="plan__price plan__price_extra">$0.00</p>
              <InButton
                isFilled
                id="free"
                className={`plan__btn plan__btn_active`}
              >
                Selected
              </InButton>
            </div>
            {selectedPeriod === 'Monthly' && (
              <div className="subscription__plan">
                <div className="plan__title-row">
                  <DoubleSwitcher
                    left="Monthly"
                    right="Annual"
                    isLeft
                    onSwitch={(isLeft: boolean) =>
                      setSelectedPeriod(isLeft ? 'Monthly' : 'Yearly')
                    }
                    className="plan__switcher"
                  />
                  <h4 className="plan__title">Pro</h4>
                </div>
                <p className="plan__subtitle">
                  Best for individuals and growing business
                </p>
                <ul className="plan__list">
                  <li className="plan__list-item">
                    <p>•</p>
                    <p className="plan__list-text">
                      get unlimited consultations
                    </p>
                  </li>
                  <li className="plan__list-item">
                    <p>•</p>
                    <p className="plan__list-text">unlock all professionals</p>
                  </li>
                </ul>
                <p className="plan__price plan__price_extra">$9.99 / month</p>
                <SubscribeButton
                  plan="Monthly"
                  paid={isMonthly}
                  canceled={!!paymentInfo?.isCanceled}
                  onSubscribe={handleSubscribeClick}
                  onUnsubscribe={handleUnsubscribeClick}
                />
              </div>
            )}
            {selectedPeriod === 'Yearly' && (
              <div className="subscription__plan">
                <div className="plan__sale">Save 35%</div>
                <div className="plan__title-row">
                  <DoubleSwitcher
                    left="Monthly"
                    right="Annual"
                    isLeft={false}
                    onSwitch={(isLeft: boolean) =>
                      setSelectedPeriod(isLeft ? 'Monthly' : 'Yearly')
                    }
                    className="plan__switcher"
                  />
                  <h4 className="plan__title">Pro</h4>
                </div>
                <p className="plan__subtitle">
                  Best for individuals and growing business
                </p>
                <ul className="plan__list">
                  <li className="plan__list-item">
                    <p>•</p>
                    <p className="plan__list-text">
                      get unlimited consultations
                    </p>
                  </li>
                  <li className="plan__list-item">
                    <p>•</p>
                    <p className="plan__list-text">unlock all professionals</p>
                  </li>
                </ul>
                <p className="plan__subprice">$69.99 / year</p>
                <p className="plan__price">$5.83 / month</p>
                <SubscribeButton
                  plan="Yearly"
                  paid={isYearly}
                  canceled={!!paymentInfo?.isCanceled}
                  onSubscribe={handleSubscribeClick}
                  onUnsubscribe={handleUnsubscribeClick}
                />
              </div>
            )}
            <div className="subscription__plan">
              <h4 className="plan__title">Business</h4>
              <p className="plan__subtitle">
                Best for businesses that require a custom plan
              </p>
              <ul className="plan__list">
                <li className="plan__list-item">
                  <p>•</p>
                  <p className="plan__list-text">get unlimited consultations</p>
                </li>
                <li className="plan__list-item">
                  <p>•</p>
                  <p className="plan__list-text">unlock all professionals</p>
                </li>
                <li className="plan__list-item">
                  <p>•</p>
                  <p className="plan__list-text">integrate with API</p>
                </li>
                <li className="plan__list-item">
                  <p>•</p>
                  <p className="plan__list-text">
                    get personal technical support
                  </p>
                </li>
              </ul>
              <p className="plan__price plan__price_extra">Let's talk</p>
              <a href="mailto:support@exh.ai" style={{width: '100%'}}>
                <InButton isFilled id="free" className={`plan__btn`}>
                  Contact us
                </InButton>
              </a>
            </div>
          </div>
        </div>
      </div>
      <UnsubscribeModal
        openned={unsubscribeModalOpened}
        onClose={() => setUnsubscribeModalOpened(false)}
        onSuccess={handleConfirmUnsubscribe}
        date={paymentInfo?.renewalDate || ''}
      />
    </main>
  );
};

type SubscriptionPageProps = {
  refreshUserInfo: (noLoader?: boolean) => void;
  loading: boolean;
};
export const SubscriptionPage = ({
  refreshUserInfo,
  loading,
}: SubscriptionPageProps) => {
  useEffect(() => {
    refreshUserInfo(true);
  }, []);
  return (
    <MainContext.Consumer>
      {({userInfo, isUserInfoLoading}) => {
        return (
          <SubscriptionPageInner
            userInfo={userInfo}
            isUserInfoLoading={isUserInfoLoading}
            loading={loading}
          />
        );
      }}
    </MainContext.Consumer>
  );
};
