export const enum PaymentStatus {
  paid = 'paid',
  unpaid = 'unpaid',
}

export type UserApiType = {
  email: string;
  paymentStatus: PaymentStatus;
  paymentInfo: Record<string, string> | null;
  stripeCustomerId: string | null;
  api_key: string;
  loginType: 'google' | 'email' | 'facebook' | 'apple';
  id: string;
};

export type LoginGoogleResponse = {
  user: any;
  token: string;
  isNew?: boolean;
};

export type PaymentPlan = 'Monthly' | 'Yearly';

export type UserPaymentInfo = {
  sum: string;
  billingPeriod: null | PaymentPlan;
  renewalDate: string | null;
  messagesUsed: number;
  invoiceUrl: string | null;
  isCanceled: boolean;
};

export type InternalEventData = {
  name: string;
  userProperties: {
    id: string;
    device_category: 'desktop' | 'mobile';
  };
  eventProperties: Record<string, string>;
};
