import {useNavigate} from 'react-router-dom';
import './BotChatHeader.css';
import logoImg from './images/logo-new.svg';
import backImg from './images/back.svg';
import {URLS} from '../../../../common/constants';
import {MainContext} from '../../../App/App';
import {InButton} from '../../../../common-lib/src/components/InButton/InButton';
import {UserLogo} from './UserLogo/UserLogo';
import {useAuth0} from '@auth0/auth0-react';

type Props = {
  withBack?: boolean;
  className?: string;
};

export const BotChatHeader = ({withBack = false, className}: Props) => {
  const navigate = useNavigate();
  const {isLoading} = useAuth0();
  const handleBackClick = () => {
    navigate(URLS.main);
  };
  return (
    <MainContext.Consumer>
      {({userInfo, openLoginModal}) => {
        return (
          <div
            className={`bot-chat-header bot-chat-header_bot ${className || ''}`}
          >
            {withBack && (
              <div className="bot-chat-header__back" onClick={handleBackClick}>
                <img
                  src={backImg}
                  alt="Back"
                  className="bot-chat-header__back-img"
                />
                <p className="bot-chat-header__back-text">Back</p>
              </div>
            )}
            <a
              href="/"
              className="bot-chat-header__logo-block bot-chat-header__logo-block_bot"
            >
              <img
                src={logoImg}
                alt="Ex-Human logo"
                className="bot-chat-header__logo"
              />
            </a>
            <div className="bot-chat-header__links bot-chat-header__links_bot">
              {!userInfo && (
                <>
                  <a className="bot-chat-header__link" href="/#how-it-works">
                    How it works
                  </a>
                  <a className="bot-chat-header__link" href="/#why-use">
                    Why use
                  </a>

                  {!isLoading && (
                    <InButton
                      id="login"
                      className="login-btn login-btn_bot"
                      onClick={openLoginModal}
                    >
                      Log In
                    </InButton>
                  )}
                </>
              )}
              {userInfo && <UserLogo />}
            </div>
          </div>
        );
      }}
    </MainContext.Consumer>
  );
};
