import {convertToId} from '../../common-lib/src/utils';
import './Chat.css';

type ChatRowProps = {
  name: string;
  src: string;
  onClick: () => void;
  selected: boolean;
};

export const ChatSidebarRow = ({
  name,
  src,
  onClick,
  selected,
}: ChatRowProps) => {
  const buttonId = `bot-${convertToId(name)}`;
  return (
    <button className="chat__row" onClick={onClick} id={buttonId}>
      <img
        src={src}
        alt={name}
        className={`chat__avatar chat__avatar_small ${
          selected ? 'chat__avatar_selected' : ''
        }`}
      />
      <p className="chat__name">{name}</p>
    </button>
  );
};
