import {InButton} from '../InButton/InButton';
import './Verify.css';
import {useNavigate} from 'react-router-dom';

export const Verify = () => {
  const navigate = useNavigate();

  return (
    <div className="verify">
      <div className="verify__container">
        <h2 className="verify__title">Please, verify your email</h2>
        <div className="verify__text">
          We have sent you an email with a link to verify your email address.
        </div>
        <div className="verify__text">
          Please, check your email and click on the link to verify your email
          address.
        </div>
        <div className="verify__text">
          If you don't see the email, check other places it might be, like your
          junk, spam, social, or other folders.
        </div>
        <InButton
          className="verify__button"
          onClick={() => {
            navigate('/');
          }}
          id="verify_back"
        >
          Return to main Page
        </InButton>
      </div>
    </div>
  );
};
