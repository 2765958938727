import {Paper, InputBase} from '@mui/material';
import {useEffect, useRef} from 'react';
import {ChatMain} from '../../../../common-lib/src/components/ChatCommon/ChatMain/ChatMain';
import send from '../../images/send.svg';
import './BotChat.css';
import {SmartReplies} from './SmartReplies';
import {useChat} from './useChat';
import {ProfInfo} from '../../../ProfTile/ProfTile';

type Props = {
  bot: ProfInfo;
  useChatValue: ReturnType<typeof useChat>;
};

export const BotChatComponent = ({bot, useChatValue}: Props) => {
  const {
    handleSmartReplyClick,
    focusInput,
    handleSendMessage,
    context,
    isLoading,
    inputValue,
    smartReplies,
    setInputValue,
  } = useChatValue;
  const chatRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const update = () => {
      if (chatRef.current) {
        const parent = chatRef.current.parentElement;
        if (!parent) {
          return;
        }
        chatRef.current.style.height = `${parent.offsetHeight - 25}px`;
      }
    };
    update();

    window.addEventListener('resize', update);

    return () => {
      window.removeEventListener('resize', update);
    };
  }, [bot]);

  useEffect(() => {
    if (!chatRef.current || context.length < 4) {
      return;
    }
    const messageEl = chatRef.current.firstChild as HTMLElement;
    if (messageEl.offsetTop < 30) {
      chatRef.current.style.display = 'block';
    }
  }, [context]);

  useEffect(() => {
    if (chatRef?.current) {
      chatRef.current.scrollTo(0, chatRef.current.scrollHeight);
      focusInput();
    }
  }, [isLoading, context, smartReplies]);

  return (
    <div className="bot-chat">
      <div className="bot-chat__header">
        AI specialists’ answers are for informational purposes only
      </div>
      <div className="bot-chat__window">
        <div className="bot-chat__window-chat" ref={chatRef}>
          <ChatMain
            isLoading={isLoading}
            withPhoto
            messages={context}
            person={{
              name: bot.name,
              src: bot.src,
              video: bot.idleUrl,
            }}
          />
          {!!smartReplies.length && (
            <SmartReplies
              messages={smartReplies}
              onClick={handleSmartReplyClick}
            />
          )}
        </div>
      </div>
      <div className="bot-chat__input">
        <Paper
          onSubmit={e => {
            e.preventDefault();
            handleSendMessage(inputValue);
          }}
          component="form"
          sx={{
            p: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 'calc(100% - 8px)',
            minHeight: '40px',
            border: '2px solid var(--main-color)',
            borderRadius: '24px',
            boxShadow: 'none',
            backgroundColor: 'white',
          }}
        >
          <InputBase
            sx={{ml: 1, flex: 1, color: '#8A89AB'}}
            placeholder={`Type here...`}
            inputProps={{'aria-label': 'message_text'}}
            value={inputValue}
            onChange={e => {
              setInputValue(e.target.value);
            }}
            onKeyDown={e => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSendMessage(inputValue);
              }
            }}
            onFocus={() => focusInput()}
            id="chat-input"
            autoComplete="off"
            ref={inputRef}
            maxRows={2}
            multiline
          />
          <button
            className="bot-chat__send-button"
            type="button"
            disabled={!inputValue}
            onClick={() => handleSendMessage(inputValue)}
          >
            <img src={send} alt="send message" />
          </button>
        </Paper>
      </div>
    </div>
  );
};
