import {Modal} from '@mui/material';
import {InButton} from '../../common-lib/src/components/InButton/InButton';
import './UnsubscribeModal.css';

type Props = {
  openned: boolean;
  onClose: () => void;
  onSuccess: () => void;
  date: string;
};

export const UnsubscribeModal = ({
  openned,
  onClose,
  onSuccess,
  date,
}: Props) => {
  return (
    <Modal className="unsubscribe-modal" open={openned} onClose={onClose}>
      <div className="column unsubscribe-modal__inner">
        <h2 className="unsubscribe__title">We're sorry to see you go!</h2>
        <p className="unsubscribe__text">
          Your subscription is paid until <span>{date}</span>
        </p>
        <p className="unsubscribe__text">
          If you'd like to proceed, please select "Unsubscribe".
        </p>
        <div className="btns">
          <InButton
            className="btns__btn btns__cancel"
            isFilled
            id="cancel"
            onClick={onClose}
          >
            Close
          </InButton>
          <InButton
            className="btns__btn btns__unsubscribe"
            isFilled
            id="unsubscribe"
            onClick={onSuccess}
          >
            Unsubscribe
          </InButton>
        </div>
      </div>
    </Modal>
  );
};
