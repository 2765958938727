import {BotData} from './Api.types';
import {TooManyRequestsError} from './Errors';
import {botDataMock, getRandomPredefinedAnswer} from './mocks';

const MAX_CHAT_REQUESTS = 30;
const MAX_TH_REQUESTS = 10;

export const generateUUID = () => {
  let d = new Date().getTime();
  if (
    typeof performance !== 'undefined' &&
    typeof performance.now === 'function'
  ) {
    d += performance.now(); // use high-precision timer if available
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxxxxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export type Message = {
  turn: string;
  message: string;
  image?: string;
};

export type Response = {
  response: string;
};

export type ChatRequestOptions = {
  lipsync?: boolean;
  send_photo?: boolean;
  voice_name?: string;
  idle_url?: string;
  predefined?: boolean;
  animation_pipeline?: string;
};

export type BotDataForRequest = {
  name: string;
  voice_name?: string;
  idle_url?: string;
  persona_facts: string[];
};

const DEFAULT_TOKEN = '';

class Api {
  static readonly previousUidKey = 'pud';

  url: string;
  token: string;
  underageUrl: string;
  emailUrl: string;
  constructor() {
    this.url = 'https://api.exh.ai/chatbot/v1';
    this.emailUrl = 'https://api.exh.ai/mail/v1/collect_email';
    this.underageUrl = 'https://api.exh.ai/underage_clf/v1/detect_underage';
    this.token = DEFAULT_TOKEN;
  }

  setToken(token: string) {
    this.token = token;
  }

  setTokenCookie(token: string) {
    this.setCookie('token', token, 1);
  }

  getTokenFromCookies(): string {
    return this.getCookie('token') || '';
  }

  getUserId() {
    const uid = this.getCookie('user_id');
    if (!uid) {
      const newUid = generateUUID();
      this.setCookie('user_id', newUid, 365);
      return newUid;
    }
    return uid;
  }

  setUserIdCookie(uid: string, withCopyPrevUid = false) {
    if (withCopyPrevUid) {
      this.copyPrevUid();
    }
    this.setCookie('user_id', uid, 365);
  }

  copyPrevUid() {
    const prevUid = this.getUserId();
    this.setCookie(Api.previousUidKey, prevUid, 365);
  }

  getPrevUid() {
    return this.getCookie(Api.previousUidKey) || this.getUserId();
  }

  private getCookie(name: string) {
    const matches = document.cookie.match(
      new RegExp(
        '(?:^|; )' +
          name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
          '=([^;]*)'
      )
    );
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  private setCookie(name: string, value: string | number, days?: number) {
    const d = days || 365;
    const options = {
      path: '/',
      samesite: 'lax',
      secure: true,
      maxAge: 60 * 60 * 24 * d,
    };

    value = encodeURIComponent(value);
    let updatedCookie = `${name}=${value}`;
    for (const propName in options) {
      updatedCookie += `; ${propName}`;

      //@ts-ignore
      const propValue = options[propName];
      if (propValue !== true) {
        updatedCookie += `=${propValue}`;
      }
    }
    document.cookie = updatedCookie;
  }

  private setCounter(k: string, v: number) {
    const nv = '7!sds_sdnf022_plo!dd=dd'
      .split('')
      .map((x, i) => (i === 15 ? v : x))
      .join('');
    this.setCookie(k, nv);
  }

  private getCounter(k: string) {
    const v = this.getCookie(k);
    if (!v) {
      return 0;
    }
    return parseInt(v.slice(15));
  }

  private updateCounter(key: string, max: number) {
    const counter = this.getCounter(key);
    if (counter >= max) {
      return false;
    }

    this.setCounter(key, counter + 1);
    return true;
  }

  chatFromSmartReply(
    name: string,
    context: Message[],
    skipLimit = false,
    signal?: AbortSignal
  ) {
    if (!skipLimit && !this.updateCounter('jh7df_ds3', MAX_CHAT_REQUESTS)) {
      return this.rejectTooManyRequests();
    }

    return this.chat(name, context, {}, signal);
  }

  async chatfromDigitalHumans(
    name: string,
    context: Message[],
    skipLimit: boolean,
    options: ChatRequestOptions,
    signal?: AbortSignal
  ) {
    if (
      !skipLimit &&
      !this.updateCounter('md2_kli7ch!-dff', MAX_CHAT_REQUESTS)
    ) {
      return this.rejectTooManyRequests();
    }

    let data = {} as any;
    try {
      data = await this.chat(name, context, options, signal).catch((e) => {
        console.error(e);
        console.warn('retry');
        return this.chat(name, context, options, signal);
      });
    } catch (e) {
      console.error(e);
      data = {response: getRandomPredefinedAnswer()};
    }

    if (!data?.response) {
      return {response: getRandomPredefinedAnswer()};
    }
    return data;
  }

  private rejectTooManyRequests() {
    return Promise.reject(new TooManyRequestsError());
  }

  chatFromEditBot(bot: BotData, context: Message[], signal?: AbortSignal) {
    return this.chatWithCustomBot(bot, context, !!bot.videoUrl, false, signal);
  }

  chatText(bot: BotData, context: Message[], signal?: AbortSignal) {
    return this.chatWithCustomBot(bot, context, false, false, signal);
  }

  public chatWithCustomBot(
    bot: BotDataForRequest,
    context: Message[],
    lipsync: boolean,
    send_photo: boolean,
    signal?: AbortSignal
  ) {
    const body: any = {
      name: bot.name,
      user_id: this.getUserId(),
      context: this.prepareContext(context),
      predefined: false,
      lipsync: lipsync,
      send_photo: send_photo,
      persona_facts: bot.persona_facts,
    };
    if (lipsync) {
      body.voice_name = bot.voice_name;
      body.idle_url = bot.idle_url;
    }
    return fetch(`${this.url}/get_response`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(body),
      signal,
    }).then((res) => {
      if (res.ok) {
        return res.json();
      }
      if (res.status === 403) {
        throw new Error(res.status.toString());
      }
      throw new Error(res.statusText);
    });
  }

  private prepareContext(context: Message[]) {
    return context
      .filter(({message, turn}) => !!message && !!turn)
      .map(({message, turn}) => ({message, turn}));
  }

  private chat(
    name: string,
    context: Message[],
    options: ChatRequestOptions,
    signal?: AbortSignal
  ) {
    const {lipsync, send_photo, ...rest} = options;
    const body: any = {
      name,
      user_id: this.getUserId(),
      context: this.prepareContext(context),
      predefined: true,
      lipsync: !!lipsync,
      send_photo: !!send_photo,
      ...rest,
    };

    return fetch(`${this.url}/get_response`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(body),
      signal,
    }).then((res) => res.json());
  }

  smartReply(name: string, context: Message[], signal?: AbortSignal) {
    const body = {
      name,
      user_id: this.getUserId(),
      context: this.prepareContext(context),
      predefined: true,
      candidates_num: 2,
    };
    return fetch(`${this.url}/get_smart_replies`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(body),
      signal,
    }).then((res) => res.json());
  }

  private getLastMessageText(context: Message[]): string {
    return context.length ? context[context.length - 1].message : 'init';
  }

  chatMock(name: string, context: Message[]): Promise<Response> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({response: `Answer to ${this.getLastMessageText(context)}`});
      }, 2000);
    });
  }

  generateLipsync(
    message: string,
    name: string,
    voice_name?: string,
    idle_url?: string,
    pipe?: string
  ) {
    const body: any = {
      text: message,
    };
    if (voice_name) {
      body['voice_name'] = voice_name;
    }
    if (idle_url) {
      body['idle_url'] = idle_url;
    }

    body['animation_pipeline'] = pipe || 'optimal';

    return fetch('https://api.exh.ai/animations/v3/generate_lipsync', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(body),
    }).then((res) => {
      if (res.ok) {
        return res.blob();
      }
      if (res.status === 403) {
        throw new Error(res.status.toString());
      }
      throw new Error(res.statusText);
    });
  }

  getFile(message: string, name: string) {
    if (!this.updateCounter('aasvds3-22!lo', MAX_TH_REQUESTS)) {
      return this.rejectTooManyRequests();
    }

    return this.generateLipsync(message, name);
  }

  contactInfo({
    name,
    email,
    companyName = '',
    reasons = [],
  }: {
    name: string;
    email: string;
    companyName?: string;
    reasons?: string[];
  }) {
    const body = {
      email_address: email,
      user_name: name,
      company_name: companyName,
      reasons,
    };
    return fetch(this.emailUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
  }

  getBotInfo(id: string): Promise<BotData> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(botDataMock), 1000);
    });
  }

  async isUnderAge(messages: string[]): Promise<boolean[]> {
    const body = {
      messages,
    };
    const data: {is_underage: boolean[]} = await fetch(this.underageUrl, {
      method: 'POST',
      body: JSON.stringify(body),
    }).then((res) => res.json());

    return data.is_underage;
  }
}

export const api = new Api();
