import {Paper, InputBase} from '@mui/material';
import sendMessageImg from '../../../images/message-arrow.png';
import './ChatFooter.css';

type Props = {
  onSubmit: (message: string) => void;
  onChange: (message: string) => void;
  value: string;
  disabled?: boolean;
};

export const ChatFooter = ({
  onChange,
  onSubmit,
  value,
  disabled = false,
}: Props) => {
  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (disabled) {
      return;
    }
    onSubmit(value);
  };

  const handleChange = (text: string) => {
    onChange(text.length > 140 ? text.slice(0, 140) : text);
  };

  const isDisabled = !value || disabled;

  return (
    <Paper
      onSubmit={handleFormSubmit}
      component="form"
      sx={{
        p: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 'calc(100% - 20px)',
        height: '40px',
      }}
    >
      <InputBase
        sx={{ml: 1, flex: 1}}
        placeholder="Type here..."
        inputProps={{'aria-label': 'message_text'}}
        value={value}
        onChange={e => handleChange(e.target.value)}
        id="chat-input"
        autoComplete="off"
      />
      <button
        className={`chat__send-button ${
          isDisabled ? 'chat__send-button_disabled' : 'chat__send-button_active'
        }`}
        type="button"
        onClick={() => onSubmit(value)}
        disabled={isDisabled}
      >
        <img src={sendMessageImg} alt="send message" />
      </button>
    </Paper>
  );
};
