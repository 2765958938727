export const addSlideInClass = () => {
  const container = document.querySelector('.chat-block__chat');
  if (container) {
    const parent = container.parentElement;

    if (!parent?.dataset?.wasslide) {
      container.classList.add('slideInUp');
      parent?.setAttribute('data-wasslide', 'true');
    } else {
      container.classList.add('visible');
    }
  }
};
