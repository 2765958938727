import './Card.css';

type Props = {
  number: number;
  src: string;
  text: string;
};

export const Card = ({number, src, text}: Props) => {
  return (
    <div className="card">
      <div className="card__number">{number}</div>
      <img src={src} alt={text} className="card__img" />
      <p className="card__text">{text}</p>
    </div>
  );
};
