type CookieType = {
	isAgreed: boolean;
	date: string;
};

export const updateCookie = (key: string, isAgreed = true) => {
	const options = {
		path: '/',
		samesite: 'lax',
		secure: true,
		maxAge: 60 * 60 * 24 * 365,
	};

	const value: CookieType = {
		isAgreed,
		date: new Date().toISOString(),
	};

	const valueEn = encodeURIComponent(JSON.stringify(value));
	let updatedCookie = `${key}=${valueEn}`;
	for (const propName in options) {
		updatedCookie += `; ${propName}`;

		//@ts-ignore
		const propValue = options[propName];
		if (propValue !== true) {
			updatedCookie += `=${propValue}`;
		}
	}
	document.cookie = updatedCookie;
};

export const getCookie = (key: string): CookieType | null => {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${key}=`);
	if (parts.length === 2) {
		const data = parts.pop()?.split(';').shift();
		if (data) {
			return JSON.parse(decodeURIComponent(data));
		}
	}
	return null;
};

export const checkIsAgreed = (key: string) => {
	const data = getCookie(key);
	if (data) {
		return data.isAgreed;
	}
	return false;
};
