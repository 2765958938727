import {ChatPerson} from '../../common-lib/src/data/chatData';
import {ProfData, profsData} from '../../common/constants';

export type ProfChatData = ChatPerson & {
  tags: string[];
  premium?: boolean;
  id: string;
};

export const chatProfs: Array<ProfChatData> = profsData.map(
  (prof: ProfData) => ({
    id: prof.id,
    tags: prof.tags,
    name: prof.role,
    src: prof.photo,
    video: prof.video,
    premium: prof.isPremium,
  })
);

export const chatTags = chatProfs.reduce((acc, prof) => {
  prof.tags.forEach(tag => {
    if (!acc.includes(tag)) {
      acc.push(tag);
    }
  });
  return acc;
}, [] as string[]);
chatTags.unshift('Featured');

export const getByTag = (tag: string) => {
  if (tag === 'Featured') {
    const bots = [...chatProfs].sort(() => Math.random() - 0.5);
    const {premium, free} = bots.reduce(
      (acc, bot) => {
        if (bot.premium) {
          acc.premium.push(bot);
        } else {
          acc.free.push(bot);
        }
        return acc;
      },
      {premium: [], free: []} as {premium: ProfChatData[]; free: ProfChatData[]}
    );
    const selected = [...premium.slice(0, 4), ...free.slice(0, 8)].sort(
      () => Math.random() - 0.5
    );
    return selected;
  }
  return chatProfs.filter(prof => prof.tags.includes(tag));
};
