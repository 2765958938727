import {useNavigate} from 'react-router-dom';
import {BotInfo} from '../../common-lib/src/components/BotTile/BotTile';
import './ProfTile.css';
import {URLS} from '../../common/constants';
import {useState} from 'react';
import {InButton} from '../../common-lib/src/components/InButton/InButton';
import {getMessagesQty} from '../../common-lib/src/components/BotTile/MessagesQty.utils';
import {useDisplaySize} from '../../common-lib/src/hooks/useDisplaySize';
import {profAmplitudeApi} from '../../ampli/amplitudeApi';
import {MainContext, UserData} from '../App/App';
import lockImg from './images/lock.svg';
import {profApi} from '../../profApi/ProfApi';

export type ProfInfo = BotInfo & {
  questions: string[];
  isPremium: boolean;
} & Required<Pick<BotInfo, 'tags'>>;

type PropsWrapper = {
  bot: ProfInfo;
};

type Props = {
  bot: ProfInfo;
  userInfo: UserData | null;
  loginAndSubscribe: () => void;
};

export const ProfTile = ({bot}: PropsWrapper) => {
  return (
    <MainContext.Consumer>
      {({userInfo, loginAndSubscribe}) => (
        <ProfTileInner
          bot={bot}
          userInfo={userInfo}
          loginAndSubscribe={loginAndSubscribe}
        />
      )}
    </MainContext.Consumer>
  );
};

const ProfTileInner = ({bot, userInfo, loginAndSubscribe}: Props) => {
  const navigate = useNavigate();
  const {width} = useDisplaySize();
  const [hovered, setHovered] = useState(false);

  const isLocked = !userInfo?.paid && bot.isPremium; //TODO: no paid. check payment status
  const isMobile = width < 768;
  const isHoverAvailable = !isMobile && !isLocked;

  const logAmplitude = (question?: number) => {
    const category = (bot.tags[0] || '').toLowerCase().replaceAll(' ', '_');
    const key = !question ? 'main_page' : `main_question_${question}`;
    profAmplitudeApi.chatVisit(bot.id, category, key);
  };
  const handleClick = async () => {
    if (isLocked) {
      return;
    }

    //@ts-ignore
    if (window.fbq) {
      //@ts-ignore
      window.fbq('trackCustom', 'ChatNow', {chat_id: bot.id});
    }
    logAmplitude();
    try {
      await profApi.chatNowEvent(bot.id);
    } catch (e) {
      console.log(e);
    }

    navigate(`${URLS.bot}/${bot.id}`);
  };

  const handleClickUnlock = () => {
    loginAndSubscribe();
  };

  const handleQuestionClick = (question: string, n: number) => {
    localStorage.setItem('question', question);
    logAmplitude(n);
    navigate(`${URLS.bot}/${bot.id}`);
  };
  const emptyFunc = () => {};

  return (
    <div
      className={`prof ${hovered ? 'prof_hovered' : ''} ${
        isLocked ? 'prof_locked' : ''
      }`}
      onMouseEnter={isHoverAvailable ? () => setHovered(true) : emptyFunc}
      onMouseLeave={isHoverAvailable ? () => setHovered(false) : emptyFunc}
      onClick={isMobile ? handleClick : emptyFunc}
    >
      {!hovered || isLocked ? (
        <>
          <img src={bot.src} alt={bot.name} className="avatar" />
          <h4 className="title">{bot.name}</h4>
          <p className="helped">
            {getMessagesQty(bot.messagesQty)} people helped
          </p>
          {isLocked ? (
            <InButton
              id="chat"
              className="chat-now_main chat-now_main_locked"
              onClick={handleClickUnlock}
            >
              <img src={lockImg} alt="lock" className="lock-img" />
              <p>Unlock</p>
            </InButton>
          ) : (
            <InButton id="chat" className="chat-now_main" onClick={handleClick}>
              Chat now
            </InButton>
          )}
        </>
      ) : (
        <>
          <div className="desc">
            <img src={bot.src} alt={bot.name} className="avatar_hovered" />
            <h4 className="title_hovered">{bot.name}</h4>
          </div>
          {bot.questions.map((question, i) => {
            return (
              <div
                className="question"
                key={question}
                onClick={() => handleQuestionClick(question, i + 1)}
              >
                {question}
              </div>
            );
          })}
          <InButton id="chat" onClick={handleClick}>
            Chat now
          </InButton>
        </>
      )}
    </div>
  );
};
