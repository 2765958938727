export const getMessagesQty = (messagesQty?: number): string => {
	if (!messagesQty) {
		const fakeMsgQty = Math.floor(Math.random() * 10000);
		return getMessagesQty(fakeMsgQty);
	}
	if (messagesQty > 100) {
		//round to 1 decimal place
		return `${Math.round(messagesQty / 100) / 10}k`;
	}
	return messagesQty.toString();
}