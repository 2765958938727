import React from 'react';
import './DoubleSwitcher.css';

type Props = {
  left: string;
  right: string;
  onSwitch: (isLeft: boolean) => void;
  isLeft: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export const DoubleSwitcher = ({
  left,
  right,
  isLeft,
  onSwitch,
  className,
  ...rest
}: Props) => {
  return (
    <div className={`double-switcher ${className}`} {...rest}>
      <div
        className={`option ${isLeft ? 'option_selected' : ''}`}
        onClick={() => onSwitch(true)}
      >
        <div className="text">{left}</div>
      </div>
      <div
        className={`option ${!isLeft ? 'option_selected' : ''}`}
        onClick={() => onSwitch(false)}
      >
        <div className="text">{right}</div>
      </div>
    </div>
  );
};
