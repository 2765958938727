import {BotData, BotType} from './Api.types';
import ElonMuskVideo from '../images/demo/ElonMusk.mp4';
import ElonMuskPhoto from '../images/avatars/ElonMusk.webp';

export const botDataMock: BotData = {
	name: 'Elon Musk',
	id: 'elon-musk',
	status: 'active',
	greeting: 'Hello world!',
	videoUrl: ElonMuskVideo,
	photoUrl: ElonMuskPhoto,
	voice: 'Marc',
	features: [
		'Security',
	],
	persona_facts: [
		'I like food',
		'I like to eat',
	],
	type: BotType.REGULAR,
}

export const predefinedAnswers = [
	'hmmm...',
	'let me think'
]

export const getRandomPredefinedAnswer = () => predefinedAnswers[Math.floor(Math.random() * predefinedAnswers.length)]