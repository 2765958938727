import {Slide, Dialog} from '@mui/material';
import {TransitionProps} from 'notistack';
import React, {useEffect, useState} from 'react';
import {MessagesQty} from '../../../../../common-lib/src/components/BotTile/MessagesQty';
import './ProfileModal.css';
import CloseIcon from '@mui/icons-material/Close';
import {getBots} from '../../../../../common/utils';
import {OtherBotTile} from '../../../../OtherBotTile/OtherBotTile';
import {useParams} from 'react-router-dom';
import {LikeReport} from '../../LikeReport/LikeReport';
import {chatStorage} from '../../../../../chatStore/ChatStorage';
import {FeedbackModal} from '../../../../FeedbackModal/FeedbackModal';
import {useFeedbackModal} from '../../../../FeedbackModal/useFeedbackModal';
import {ProfInfo} from '../../../../ProfTile/ProfTile';
import {MainContext, UserData} from '../../../../App/App';

type Props = {
  bot: ProfInfo;
  onClose: () => void;
  isOpened: boolean;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ProfileModal = ({bot, onClose, isOpened}: Props) => {
  return (
    <MainContext.Consumer>
      {({userInfo, loginAndSubscribe}) => {
        const handleUnclockClick = () => {
          localStorage.setItem('unlock', 'unlock');
          loginAndSubscribe();
        };
        return (
          <ProfileModalInner
            userInfo={userInfo}
            bot={bot}
            onClose={onClose}
            isOpened={isOpened}
            onUnlockClick={handleUnclockClick}
          />
        );
      }}
    </MainContext.Consumer>
  );
};

const ProfileModalInner = ({
  bot,
  onClose,
  isOpened,
  userInfo,
  onUnlockClick,
}: Props & {userInfo: UserData | null; onUnlockClick: () => void}) => {
  const params = useParams();
  const botId = params.botId;
  const [isLiked, setIsLiked] = useState(chatStorage.isLiked(bot.id));
  const {
    isFeedbackModalOpen,
    handleSubmitReport,
    closeFeedbackModal,
    openFeedbackModal,
  } = useFeedbackModal(botId || '');
  const [otherBots, setOtherBots] = useState<ProfInfo[]>([]);
  const [categoryBots, setCategoryBots] = useState<ProfInfo[]>([]);
  const [visitedBots, setVisitedBots] = useState<ProfInfo[]>([]);

  useEffect(() => {
    if (!bot) {
      return;
    }
    const added = new Set();
    const bots = getBots().filter(x => x.id !== bot.id);
    const sameCategory = bots
      .filter(x => x.tags.includes(bot.tags[0]))
      .sort(() => (Math.random() > 0.5 ? 1 : -1))
      .slice(0, 3);
    setCategoryBots(sameCategory);
    sameCategory.forEach(x => added.add(x.id));

    const visitedIds = chatStorage.getChatVisited();
    const visited = visitedIds
      ? bots
          .filter(x => visitedIds.includes(x.id) && !added.has(x.id))
          .slice(0, 3)
          .reverse()
      : [];
    setVisitedBots(visited);
    visited.forEach(x => added.add(x.id));

    const other = bots
      .filter(x => !added.has(x.id))
      .sort(() => (Math.random() > 0.5 ? 1 : -1))
      .slice(0, 3);
    setOtherBots(other);
  }, [bot]);

  const handleLike = () => {
    setIsLiked(!isLiked);
    if (isLiked) {
      chatStorage.unlike(bot.id);
    } else {
      chatStorage.like(bot.id);
    }
  };

  useEffect(() => {
    onClose();
  }, [botId]);

  return (
    <Dialog
      fullScreen
      open={isOpened}
      onClose={onClose}
      TransitionComponent={Transition}
      sx={{
        height: '90vh',
        marginTop: '10vh',
        backgroundColor: '#E9E8EF',
      }}
      className="profile-modal"
    >
      <CloseIcon
        className="close-icon"
        onClick={onClose}
        sx={{color: '#5C6269', marginLeft: 'auto', cursor: 'pointer'}}
      />
      <img src={bot.src} alt="avatar" className="avatar" />
      <h2 className="name">{bot.name}</h2>
      <div className="messages-row">
        <MessagesQty messagesQty={bot.messagesQty} type="help" />
        <p className="text"> people helped</p>
      </div>
      <p className="description">{bot.description}</p>
      <LikeReport
        botId={bot.id}
        onLike={handleLike}
        onReport={openFeedbackModal}
        isLiked={isLiked}
      />
      <div className="other-profs">
        <div className="bot-info__others-block">
          <h3 className="other-prof-title">{bot.tags[0]} Professionals:</h3>
          <div className="bot-info__other-bots">
            {categoryBots.map((bot, index) => (
              <OtherBotTile
                bot={bot}
                key={bot.id + index}
                onUnlockClick={onUnlockClick}
              />
            ))}
          </div>
        </div>
        <div className="bot-info__others-block">
          <h3 className="other-prof-title">Other Professionals:</h3>
          <div className="bot-info__other-bots">
            {otherBots.map((bot, index) => (
              <OtherBotTile
                bot={bot}
                key={bot.id + index}
                onUnlockClick={onUnlockClick}
              />
            ))}
          </div>
        </div>
        {visitedBots.length && (
          <div className="bot-info__others-block">
            <h3 className="other-prof-title">Recently used:</h3>
            <div className="bot-info__other-bots">
              {visitedBots.map((bot, index) => (
                <OtherBotTile
                  bot={bot}
                  key={bot.id + index}
                  onUnlockClick={onUnlockClick}
                />
              ))}
            </div>
          </div>
        )}
      </div>
      <FeedbackModal
        onSubmit={handleSubmitReport}
        openned={isFeedbackModalOpen}
        onClose={closeFeedbackModal}
      />
    </Dialog>
  );
};
